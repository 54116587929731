import Header from "../../components/Header";
import Footer from "../../components/Footer";
import FormReceberAtualizacoes from "../../components/FormReceberAtualizacoes";

export default function PageLayout({ children }) {
	return (
		<>
			<Header />
			{children}
			<FormReceberAtualizacoes />
			<Footer />
		</>
	);
}
