import { useEffect } from "react";
import {
	createBrowserRouter,
	RouterProvider,
	ScrollRestoration,
	Outlet,
} from "react-router-dom";
import PageInicial from "./pages/home";
import PageSejaUmParceiro from "./pages/seja-parceiro";
import PageFaleConosco from "./pages/fale-conosco";
import PageTrabalheConosco from "./pages/trabalhe-conosco";
import PageSobreSifat from "./pages/sobre-sifat";
import PageSobreWaybe from "./pages/sobre-waybe";
import PageSobreProdati from "./pages/sobre-prodati";
import PageWaychef from "./pages/waychef";
import PageEfex from "./pages/efex";
import PageFlutt from "./pages/flutt";
import PageLumien from "./pages/lumien";
import PageWaymenu from "./pages/waymenu";
import PagePrivacidade from "./pages/privacidade";
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const AppLayout = () => (
	<>
		<ScrollRestoration />
		<Outlet />
	</>
);

const router = createBrowserRouter([
	{
		element: <AppLayout />,
		children: [
			{
				path: "/",
				element: <PageInicial />,
			},
			{
				path: "/trabalhe-conosco",
				element: <PageTrabalheConosco />,
			},
			{
				path: "/fale-conosco",
				element: <PageFaleConosco />,
			},
			{
				path: "/seja-parceiro",
				element: <PageSejaUmParceiro />,
			},
			{
				path: "/sobre-waybe",
				element: <PageSobreWaybe />,
			},
			{
				path: "/sobre-prodati",
				element: <PageSobreProdati />,
			},
			{
				path: "/sobre-sifat",
				element: <PageSobreSifat />,
			},
			{
				path: "/waychef",
				element: <PageWaychef />,
			},
			{
				path: "/efex",
				element: <PageEfex />,
			},
			{
				path: "/flutt",
				element: <PageFlutt />,
			},
			{
				path: "/lumien",
				element: <PageLumien />,
			},
			{
				path: "/waymenu",
				element: <PageWaymenu />,
			},
			{
				path: "/privacidade",
				element: <PagePrivacidade />,
			},
		],
	},
]);

function App() {
	useEffect(() => {
		document.title = "Sifat Sistemas - Sistema para automação comercial";
	}, []);

	return (
		<>
			<RouterProvider router={router} />
		</>
	);
}

export default App;
