import styled from "styled-components";
import {
	BotaoFaleConosco,
	BotaoReceberLigacao,
	BotaoTesteGratis,
} from "../Botoes";
import Padaria from "../../assets/imagens/waychef/Padaria.svg";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1.2em;

	@media screen and (min-width: 993px) {
		flex-direction: row;
		align-items: start;
	}
`;

const Subcontainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const BotoesContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 1.2em;
	justify-content: space-around;
`;

const Titulo = styled.h1`
	color: #f26a35;
	font-weight: var(--strong-weight);
	font-size: var(--bigger-font);
	text-align: left;
`;

const Imagem = styled.img`
	width: 100%;
	max-width: 30em;
	height: auto;
	display: flex;
	align-self: center;
`;

const Paragrafo = styled.p`
	text-align: left;
	font-weight: var(--weak-weight);
	font-size: var(--medium-font);
`;

const ListaOrdenada = styled.ol``;
const ListaNaoOrdenada = styled.ul``;

const ItemLista = styled.li`
	font-weight: var(--weak-weight);
	font-size: var(--medium-font);

	&::marker {
		color: #f26a35;
		font-weight: var(--strong-weight);
	}
`;

export function PorqueProdutoWaychef() {
	return (
		<Container>
			<Subcontainer>
				<Titulo>
					Por que devo escolher Waychef como sistema de gestão do meu Food
					Service?
				</Titulo>
				<Imagem src={Padaria} alt={"Imagem de padaria"} />
			</Subcontainer>
			<Subcontainer>
				<Paragrafo>
					O Waychef foi produzido pensado especialmente para as pequenas e
					médias empresas, para o empreendedor que quer aumentar a sua
					produtividade enquanto preserva a qualidade do serviço e do
					atendimento.
				</Paragrafo>
				<Paragrafo>
					Nossa tecnologia é simplificada para você. O Waychef atende a todas as
					necessidades básicas do seu Food Service, por exemplo:
				</Paragrafo>
				<ListaOrdenada>
					<ItemLista>Vendas</ItemLista>
					<ItemLista>Cadastro de produtos</ItemLista>
					<ItemLista>Gestão de estoque</ItemLista>
					<ItemLista>Controle de entradas e saídas</ItemLista>
					<ItemLista>Gestão de pedidos delivery</ItemLista>
					<ItemLista>Integração com os principais Apps de Delivery</ItemLista>
				</ListaOrdenada>
				<Paragrafo>
					Para aumentar a sua produtividade, o Waychef também conta com
					ferramentas de gestão avançadas. Confira algumas soluções que podem
					ajudar o seu negócio:
				</Paragrafo>
				<ListaNaoOrdenada>
					<ItemLista>Controle de mesas e fichas</ItemLista>
					<ItemLista>Controle de promoções, combos e compostos</ItemLista>
					<ItemLista>Oferta de Cashback no cadastro do cliente</ItemLista>
					<ItemLista>Imprime em múltiplos locais</ItemLista>
				</ListaNaoOrdenada>
				<BotoesContainer>
					<BotaoFaleConosco />
					<BotaoReceberLigacao />
					<BotaoTesteGratis />
				</BotoesContainer>
			</Subcontainer>
		</Container>
	);
}

export function PorqueProdutoEfex() {
	return (
		<Container>
			<Subcontainer>
				<Titulo>
					POR QUE DEVO ESCOLHER O EFEX COMO PONTO DE VENDA DO MEU SUPERMERCADO?
				</Titulo>
				<Imagem src={Padaria} alt={"Imagem de padaria"} />
			</Subcontainer>
			<Subcontainer>
				<Paragrafo>
					No competitivo mercado varejista, a escolha do ponto de venda é crucial para o sucesso do seu supermercado.
					O Efex PDV é a solução ideal, oferecendo uma gama completa de recursos e integrações para atender às demandas do seu negócio:
				</Paragrafo>
				<ListaOrdenada>
					<ItemLista>Cadastro de produtos, promoções e clientes</ItemLista>
					<ItemLista>Concentrador fiscal e geração de SPED</ItemLista>
					<ItemLista>Controle de estoque e coletor de dados</ItemLista>
					<ItemLista>Controle de vasilhames</ItemLista>
					<ItemLista>Administração Financeira</ItemLista>
					<ItemLista>Homologado com Auttar e Sitef</ItemLista>
				</ListaOrdenada>
				<Paragrafo>
					Para ajudar na sua produtividade e impulsionar o crecimento do seu mercado, o Efex também possui as melhores integrações voltadas para a jornada do seu cliente. O Efex é integrado com:
				</Paragrafo>
				<ListaNaoOrdenada>
					<ItemLista>Site Mercado</ItemLista>
					<ItemLista>Scanntech</ItemLista>
					<ItemLista>Mercafácil e Cresce Vendas (CRM)</ItemLista>
					<ItemLista>Terminal de autoatendimento</ItemLista>
					<ItemLista>Balança de pesagem</ItemLista>
				</ListaNaoOrdenada>
				<BotoesContainer>
					<BotaoFaleConosco />
					<BotaoReceberLigacao />
					<BotaoTesteGratis />
				</BotoesContainer>
			</Subcontainer>
		</Container>
	);
}

export function PorqueProdutoFlutt() {
	return (
		<Container>
			<Subcontainer>
				<Titulo>
					POR QUE DEVO ESCOLHER O FLUTT COMO PONTO DE VENDA DA MINHA LOJA?
				</Titulo>
				<Imagem src={Padaria} alt={"Imagem de padaria"} />
			</Subcontainer>
			<Subcontainer>
				<Paragrafo>
					O Flutt foi desenvolvido especialmente para atender às necessidades das pequenas e médias empresas, proporcionando uma gestão eficiente e simplificada para o seu negócio de varejo. Com nossa tecnologia fácil de usar, você pode aumentar sua produtividade enquanto mantém a qualidade do serviço e do atendimento.
				</Paragrafo>
				<Paragrafo>
					Nossa plataforma abrange todas as funcionalidades essenciais para o seu varejo, incluindo:
				</Paragrafo>
				<ListaOrdenada>
					<ItemLista>Registro de vendas</ItemLista>
					<ItemLista>Cadastro de produtos</ItemLista>
					<ItemLista>Gestão de estoque</ItemLista>
					<ItemLista>Cadastro de clientes</ItemLista>
					<ItemLista>Relatórios gerenciais</ItemLista>
					<ItemLista>Emissão fiscal</ItemLista>
				</ListaOrdenada>
				<Paragrafo>
					Com o Flutt, você terá todas as ferramentas necessárias para administrar seu varejo de forma eficaz e alcançar o sucesso desejado. Além disso, o Flutt oferece ferramentas de gestão criadas para impulsionar o seu negócio, como:
				</Paragrafo>
				<ListaNaoOrdenada>
					<ItemLista>Controle de trocas e condicionais</ItemLista>
					<ItemLista>Controle de vale presentes</ItemLista>
					<ItemLista>Controle de cartões</ItemLista>
					<ItemLista>Integração TEF</ItemLista>
					<ItemLista>App Mobile</ItemLista>
				</ListaNaoOrdenada>
				<BotoesContainer>
					<BotaoFaleConosco />
					<BotaoReceberLigacao />
					<BotaoTesteGratis />
				</BotoesContainer>
			</Subcontainer>
		</Container>
	);
}

export function PorqueProdutoLumien() {
	return (
		<Container>
			<Subcontainer>
				<Titulo>
					Por que escolher o Lumien para o seu posto de combustível?
				</Titulo>
				<Imagem src={Padaria} alt={"Imagem de padaria"} />
			</Subcontainer>
			<Subcontainer>
				<Paragrafo>
					O Lumien é ideal para postos de combustível que buscam uma gestão clara e objetiva. Nosso sistema é intuitivo e oferece todas as ferramentas necessárias para otimizar as operações e aumentar a eficiência do seu posto.
				</Paragrafo>
				<Paragrafo>
					O Lumien oferece funcionalidades essenciais como:
				</Paragrafo>
				<ListaOrdenada>
					<ItemLista>Integração com automação de bombas</ItemLista>
					<ItemLista>Controle preciso de estoque</ItemLista>
					<ItemLista>Gestão financeira completa</ItemLista>
					<ItemLista>Monitoramento de movimentação por turno</ItemLista>
					<ItemLista>Emissão fiscal integrada</ItemLista>
				</ListaOrdenada>
				<Paragrafo>
					Além dessas funcionalidades, o Lumien também oferece ferramentas avançadas para transformar a gestão do seu posto e aumentar a produtividade:
				</Paragrafo>
				<ListaNaoOrdenada>
					<ItemLista>Controle de frota e clientes: Cadastre e gerencie todas as informações essenciais sobre a frota e os clientes.</ItemLista>
					<ItemLista>Gestão de equipe: Controle de produtividade e bloqueio de bombas para pessoas autorizadas.</ItemLista>
					<ItemLista>Preços especiais e nivelamento: Defina preços especiais por grupo de clientes e configure preços por método de pagamento.</ItemLista>
					<ItemLista>Controle de loja de conveniência: Gerencie entradas e saídas de produtos da sua loja de conveniência.</ItemLista>
				</ListaNaoOrdenada>
				<Paragrafo>
					Com o Lumien, você moderniza a gestão do seu posto, oferecendo um serviço de alta qualidade e aumentando a satisfação dos seus clientes.
				</Paragrafo>
				<BotoesContainer>
					<BotaoFaleConosco />
					<BotaoReceberLigacao />
					<BotaoTesteGratis />
				</BotoesContainer>
			</Subcontainer>
		</Container>
	);
}

export function PorqueProdutoWaymenu() {
	return (
		<Container>
			<Subcontainer>
				<Titulo>
					Por que escolher o Waymenu como meu cardápio digital?
				</Titulo>
				<Imagem src={Padaria} alt={"Imagem de padaria"} />
			</Subcontainer>
			<Subcontainer>
				<Paragrafo>
					O Waymenu foi desenvolvido com foco nas pequenas e médias empresas que buscam aprimorar o atendimento e otimizar a eficiência do seu delivery. Nossa plataforma é fácil de usar e oferece um serviço rápido e de alta qualidade, garantindo uma experiência excepcional para seus clientes.
				</Paragrafo>
				<Paragrafo>
					O Waymenu simplifica a gestão do seu negócio de Food Service, oferecendo funcionalidades essenciais como:
				</Paragrafo>
				<ListaOrdenada>
					<ItemLista>Criação e personalização de cardápios digitais</ItemLista>
					<ItemLista>Automatização de pedidos</ItemLista>
					<ItemLista>Impressão de comandas</ItemLista>
					<ItemLista>Gestão de taxas de entrega</ItemLista>
					<ItemLista>Salvamento de endereços de clientes</ItemLista>
				</ListaOrdenada>
				<Paragrafo>
					Além dessas funcionalidades básicas, o Waymenu também conta com ferramentas avançadas que podem transformar seu negócio, aumentando a produtividade e melhorando a experiência do cliente. Confira algumas soluções que podem ajudar o seu restaurante:
				</Paragrafo>
				<ListaNaoOrdenada>
					<ItemLista>Gestor de pedidos: Controle total sobre o fluxo de pedidos, reduzindo erros e aumentando a eficiência.</ItemLista>
					<ItemLista>Configuração e cadastro de taxas: Defina taxas de entrega por raio de localização ou por bairro.</ItemLista>
					<ItemLista>Integração Waychef: Simplifique a administração do seu delivery com uma plataforma integrada.</ItemLista>
					<ItemLista>Armazenamento de endereços: Agilize o processo de pedidos salvando os endereços dos clientes na plataforma.</ItemLista>
					<ItemLista>Melhores taxas do mercado: Economize com tarifas competitivas e maximize seu lucro.</ItemLista>
				</ListaNaoOrdenada>
				<Paragrafo>
					Com o Waymenu, você moderniza seu serviço de delivery, oferece mais conforto e fideliza seus clientes com uma plataforma prática e eficiente. Transforme a gestão do seu restaurante e foque no crescimento do seu negócio com as soluções inovadoras do Waymenu.
				</Paragrafo>
				<BotoesContainer>
					<BotaoFaleConosco />
					<BotaoReceberLigacao />
					<BotaoTesteGratis />
				</BotoesContainer>
			</Subcontainer>
		</Container>
	);
}

