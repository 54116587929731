import styled from "styled-components";

const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 15em;
`;

const Imagem = styled.img`
	width: ${(props) => props.$width || '5em'};
	height: 5em;
	margin-bottom: 1em;
	filter: ${(props) => props.$filter || 'invert(1)'};
`;

const Titulo = styled.h1`
	font-size: var(--small-font);
	font-weight: var(--strong-weight);
	color: white;
	text-align: center;
`;

const Texto = styled.p`
	font-size: var(--small-font);
	color: white;
	text-align: center;
	font-weight: var(--weak-weight);
`;

export default function CardVantagem({ imagem, titulo, texto, alt, filter, width }) {
	return (
		<>
			<CardContainer>
				<Imagem src={imagem} alt={alt} $filter={filter} $width={width} />
				<Titulo>{titulo}</Titulo>
				<Texto>{texto}</Texto>
			</CardContainer>
		</>
	);
}
