import styled from "styled-components";
import CardVantagem from "../CardVantagem";
import { BotaoQueroSerParceiro } from "../Botoes";
// Imagens
import IconeLupa from "../../assets/icones/opportunity.png";
import IconeAtendente from "../../assets/icones/help-desk.png";
import Icone28anos from "../../assets/icones/28 anos.svg";
import IconeTecnologia from "../../assets/icones/digitalization.png";
import IconeApertoMaos from "../../assets/icones/handshake.png";
import IconePlataforma from "../../assets/icones/crm.png";

const VantagensContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2em;
	width: 100%;
	padding: 4em 1em;

	@media screen and (min-width: 350px) {
		padding: 4em 2em;
	}
`;

const VantagensCardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	gap: 2em;

	@media screen and (min-width: 650px) {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-around;
		align-items: flex-start;
	}
`;

const Titulo = styled.h1`
	font-weight: var(--strong-weight);
	font-size: var(--bigger-font);
	text-align: center;
	color: white;
`;

export default function VantagensDoParceiro() {
	return (
		<VantagensContainer>
			<Titulo>Vantagens de ser um parceiro Sifat</Titulo>
			<VantagensCardWrapper>
				<CardVantagem
					imagem={IconeLupa}
					alt={"Ícone de uma lupa"}
					titulo={"Muitas oportunidades"}
					texto={
						"Ofereça soluções de gestão competitivas para Food Service a preços imbatíveis com a Sifat."
					}
				/>
				<CardVantagem
					imagem={IconeAtendente}
					alt={"Ícone de pessoa com headset"}
					titulo={"Treinamento excepcional"}
					texto={
						"Capacite-se com a nossa assessoria de treinamento para fornecer suporte técnico de qualidade."
					}
				/>
				<CardVantagem
					imagem={Icone28anos}
					alt={"Ícone escrito mais de 27 anos"}
					titulo={"Tradição de mercado"}
					texto={
						"Una-se a uma marca sólida com mais de 28 anos de experiência no mercado."
					}
					filter={"none"}
					width={"7em"}
				/>
				<CardVantagem
					imagem={IconeTecnologia}
					alt={"Ícone abstrato representando tecnologia"}
					titulo={"Tecnologia de ponta"}
					texto={
						"Comercialize produtos tecnológicos inovadores, com funcionalidades acessíveis em tempo real."
					}
				/>
				<CardVantagem
					imagem={IconeApertoMaos}
					alt={"Ícone de aperto de mãos"}
					titulo={"Nós também te ajudamos"}
					texto={
						"Você vende produtos? Nossos parceiros também podem ter acesso a plataforma retaguarda!"
					}
				/>
				<CardVantagem
					imagem={IconePlataforma}
					alt={"Ícone abstrato para plataforma de software"}
					titulo={"Oferecemos plataforma CRM"}
					texto={
						"Utilize a plataforma Flowdesk® para controle de clientes, leads e propostas."
					}
				/>
			</VantagensCardWrapper>
			<BotaoQueroSerParceiro  />
		</VantagensContainer>
	);
}
