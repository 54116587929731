// Componentes personalizados
import BannerNavegacao from "../../components/BannerNavegacao";
import { BalaoSobreWaybe } from "../../components/BaloesTexto";
import SecaoPagina from "../../components/SecaoPagina";
import { QualidadesWaybe } from "../../components/Qualidades";
import { ProdutosWaybe } from "../../components/GrupoCirculos";
import PageLayout from "../../layouts/PageLayout";
// Imagens
import FundoAzulMaqCelW from "../../assets/imagens/FundoAzulMaqCelW.png";
import FundoBrancoOndaWaybe from "../../assets/imagens/FundoBrancoOndaWaybe.png";
import FundoAzulOnda from "../../assets/imagens/FundoAzulOnda.webp";

export default function PaginaSobreWaybe() {
	return (
		<PageLayout>
			<BannerNavegacao pagina={"Waybe"} />
			<SecaoPagina
				$gap={"2em"}
				$bgimagem={FundoAzulMaqCelW}
				$paddingmin={"2em 1em"}
				$paddingmax={"4em 4em 0"}
				$position={"left"}
			>
				<BalaoSobreWaybe />
			</SecaoPagina>

			<SecaoPagina
				$bgimagem={FundoBrancoOndaWaybe}
				$position={"center"}
				$bgsize={"270% 215%"}
				$paddingmax={"4em 4em 0"}
			>
				<QualidadesWaybe />
			</SecaoPagina>

			<SecaoPagina
				$bgimagem={FundoAzulOnda}
				$paddingmin={"2em 2em 9em"}
				$paddingmid={"2em 2em 9em"}
				$paddingmax={"4em 4em 8em"}
				$position={"center"}
				$gap={"3em"}
			>
				<ProdutosWaybe />
			</SecaoPagina>
		</PageLayout>
	);
}
