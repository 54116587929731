import styled from "styled-components";
import { useEffect } from "react";

function importAll(r) {
	let imagensParceiros = {};

	r.keys().map((item, index) => {
		const nomeArquivo = item.split("/")[1].split(".")[0];
		imagensParceiros[index] = { imagem: r(item), key: index, alt: nomeArquivo };
	});

	return imagensParceiros;
}

const imagensParceiros = importAll(
	require.context(
		"../../assets/imagens/logosParceiros/",
		false,
		/\.(png|jpe?g|svg|webp)$/
	)
);

const Container = styled.div`
	background-color: white;
	border-radius: var(--border-radius-m);
	overflow: hidden;
	width: 85vw;
	display: flex;
	align-self: center;
	max-width: var(--max-section-w);

	@media screen and (min-width: 993px) {
		width: 100%;
	}
`;

const LogosContainer = styled.div`
	margin: 0;
	overflow: hidden;
	white-space: nowrap;
	position: relative;

	&:before,
	&:after {
		position: absolute;
		top: 0;
		width: 250px;
		height: 100%;
		content: "";
		z-index: 2;
	}
`;

const Logo = styled.img`
	width: 5em;
	height: auto;
	margin: 0.5em 1em;
`;

const Slider = styled.div`
	display: inline-block;
	animation: 20s slide infinite linear;
	overflow: hidden;

	@keyframes slide {
		from {
			transform: translateX(0);
		}

		to {
			transform: translateX(-100%);
		}
	}
`;

export default function ListaParceiros() {
	useEffect(() => {
		window.onload = function () {
			const slider = document.querySelector(".logos-slide");

			if (slider !== null && slider !== undefined) {
				var copy = slider.cloneNode(true);
				document.querySelector(".logos").appendChild(copy);
			}
		};
	});

	return (
		<Container>
			<LogosContainer className="logos">
				<Slider className={"logos-slide"}>
					{Object.keys(imagensParceiros).map((key) => {
						return (
							<Logo
								src={imagensParceiros[key].imagem}
								key={imagensParceiros[key].key}
								alt={imagensParceiros[key].alt}
							/>
						);
					})}
				</Slider>
				<Slider className={"logos-slide"}>
					{Object.keys(imagensParceiros).map((key) => {
						return (
							<Logo
								src={imagensParceiros[key].imagem}
								key={imagensParceiros[key].key}
								alt={imagensParceiros[key].alt}
							/>
						);
					})}
				</Slider>
			</LogosContainer>
		</Container>
	);
}
