export const TextosEfex = {
	0: {
		titulo: "Está disponível suporte técnico ao adquirir o sistema?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"Com certeza! Oferecemos suporte técnico completo ao adquirir o sistema. Contamos com uma central de ligações e WhatsApp, onde nossos consultores altamente qualificados estão prontos para te auxiliar em qualquer momento. Atente-se às modalidades de suporte disponíveis de acordo com o plano contratado. Nosso atendimento durante o horário comercial é de segunda a sexta-feira das 07h às 19h e aos sábados das 07h às 13h. Além disso, oferecemos plantão de atendimento de segunda a sexta-feira das 19h às 00h, aos sábados das 13h às 00h e aos domingos das 07h às 18h. Para maior comodidade, também disponibilizamos atendimento via WhatsApp de segunda a sexta-feira das 08h às 17h30."
			}
		}
	},
	1: {
		titulo: "O sistema controla vasilhame?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"Sim, nosso sistema de controle de vasilhames gera uma impressão com código de barras, exigindo a leitura pelo operador do caixa para liberar a venda."
			}
		}
	},
	2: {
		titulo: "O sistema emite boletos para clientes que compram a prazo?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"Sim, todas as vendas a prazo podem ser acompanhadas por emissão de nota fiscal ou boleto. Basta que o cliente tenha seu cadastro completo. Consulte os bancos homologados com o time comercial."
			}
		}
	},
	3: {
		titulo: "É integrado com a tecnologia TEF?",
		corpo: {
			0: {
				tipo: "p",
				texto: "Sim, nossa integração inclui os sistemas mais utilizados no mercado, como SITEF, AUTTAR, PAY GO, entre outros. Consulte a lista completa com nossa equipe comercial."
			}
		}
	},
	4: {
		titulo:
			"Envia cargas diretamente para a balança?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"Sim, as cargas são enviadas para o software das balanças, compatíveis com marcas como Toledo, Balmak, Filizola e SYSTEL. Observação: Alguns modelos funcionam apenas com o protocolo TOLEDO (SYSTEL, Balmak, etc.)."
			}
		}
	},
	5: {
		titulo: "O sistema trabalha com lucro real, presumido ou simples?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"Sim, o sistema trabalha nas três modalidades. Quando você escolhe o Simples Nacional, o sistema simplifica o processo, eliminando a necessidade de gerar SPED Fiscais, por exemplo. Isso se deve ao fato de que o regime tributário do Simples Nacional se baseia no faturamento, e os impostos são pagos de acordo com essa receita. Por outro lado, se sua empresa opera no regime de Lucro Real ou Presumido, o Efex oferece suporte completo, permitindo a geração dos arquivos SPED necessários para envio à contabilidade. Para garantir essa funcionalidade, é importante configurar corretamente os impostos de ICMS, PIS e COFINS no sistema. Dessa forma, você pode ter a tranquilidade de estar em conformidade com as obrigações fiscais e contábeis, independentemente do regime tributário em que sua empresa esteja enquadrada."
			}
		}
	},
	6: {
		titulo: "A balança funciona diretamente no PDV do caixa? (Check-out)",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"Sim, vários modelos são compatíveis com o sistema, incluindo marcas como Toledo e Filizola, que são homologadas. Outras marcas, como Elgin e Balmak, podem ser compatíveis com protocolo Toledo.",
			}
		}
	},
	7: {
		titulo: "O sistema de caixa funciona por cargas? (Offline)",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"Sim, nosso sistema opera com o envio de cargas capazes de executar todas as funcionalidades mesmo sem conexão com a rede."
			}
		}
	}
};
