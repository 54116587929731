import styled from "styled-components";

const BalaoContainer = styled.div`
	background-color: white;
	border-radius: var(--border-radius-s);
	filter: drop-shadow(1px 1px 4px black);
	padding: 1.5em;
`;

const Titulo = styled.h1`
	font-size: var(--bigger-font);
	font-weight: var(--strong-weight);
	color: ${(props) => props.$cortitulo || "black"};
`;

const Paragrafo = styled.p`
	font-size: var(--smaller-font);
	color: ${(props) => props.$corparagrafo || "black"};
	margin: 0;
	font-weight: var(--regular-weight);
`;

export default function BalaoTexto({
	titulo,
	paragrafo1,
	paragrafo2,
	$cortitulo,
	$corparagrafo,
}) {
	return (
		<>
			<BalaoContainer>
				<Titulo $cortitulo={$cortitulo} $corparagrafo={$corparagrafo}>
					{titulo}
				</Titulo>
				<Paragrafo>
					{paragrafo1}
					{paragrafo2 && (
						<>
							<br></br>
							{paragrafo2}
						</>
					)}
				</Paragrafo>
			</BalaoContainer>
		</>
	);
}
