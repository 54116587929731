// Componentes personalizados
import Form from "../../components/Form";
import VantagensDoParceiro from "../../components/VantagensDoParceiro";
import BlocoTitulo from "../../components/BlocoTituloTextoBotaoImagem";
import { BotaoFaleConosco, BotaoSejaParceiro } from "../../components/Botoes";
import SecaoPagina from "../../components/SecaoPagina";
import PageLayout from "../../layouts/PageLayout";
// Imagens
import FundoVerde from "../../assets/imagens/FundoVerdeGrafo.webp";
import Highfive from "../../assets/imagens/Highfive.svg";

export default function PaginaSejaParceiro() {
	return (
		<PageLayout>
			<SecaoPagina>
				<BlocoTitulo
					margemImagem={null}
					corParagrafo={null}
					corTitulo={null}
					tituloTipoB={false}
					imagem={Highfive}
					possuiLogosClientes={true}
					botao1={<BotaoFaleConosco />}
					botao2={<BotaoSejaParceiro />}
					paragrafo1={
						"Gerir uma empresa não precisa ser complicado e demorado."
					}
					paragrafo2={
						"Os parceiros do Grupo Sifat oferecem aos seus clientes uma alternativa para deixar a gerência eficiente e completa!"
					}
					tituloTipoA={true}
					titulo1={"Junte-se a nós em uma"}
					titulo2={"parceria de sucesso!"}
				/>
			</SecaoPagina>

			<SecaoPagina $bgcolor={"#1b355e"}>
				<VantagensDoParceiro />
			</SecaoPagina>

			<SecaoPagina
				$paddingmin={"2em 2em 9em"}
				$paddingmid={"2em 2em 9em"}
				$paddingmax={"4em 4em 8em"}
				$bgimagem={FundoVerde}
				id={"formParceiro"}
			>
				<Form
					sejaParceiro={true}
					titulo={"Seja um parceiro da Sifat!"}
					subtitulo={
						"Pronto para começar? Entre em contato hoje para agendar uma ligação. Podemos discutir todas as opções de parceria disponíveis e conhecer melhor as necessidades do seu negócio e dos seus clientes."
					}
					trabalheConosco={false}
					color={"white"}
					anexavel={true}
				/>
			</SecaoPagina>
		</PageLayout>
	);
}
