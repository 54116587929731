import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	background-color: #f4f4f3;
	border: 0.2em solid #4e4d4d;
	border-radius: var(--border-radius-m);
	padding: 1em;

	@media screen and (min-width: 650px) {
		width: 47%;
	}

	@media screen and (min-width: 993px) {
		width: 31%;
	}
`;
const LinkContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: end;
	align-items: center;
`;

const ImagemContainer = styled.div`
	object-fit: cover;
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
`;

const Link = styled.a`
	margin-right: 0.2em;
	text-decoration: none;
	color: black;
	font-weight: var(--strong-weight);
	font-size: var(--smaller-font);

	&:hover,
	&:visited {
		color: black;
	}
`;

const Imagem = styled.img`
	width: 100%;
	height: auto;
`;

const Titulo = styled.h1`
	font-weight: var(--regular-weight);
	font-size: var(--smaller-font);
`;

const Subtitulo = styled.h2`
	font-weight: var(--weak-weight);
	font-size: var(--smallest-font);
`;

export default function CardTecnologiaOferecida({ imagem, titulo, subtitulo }) {
	const arrayCaminhoImagen = imagem.split("/");
	const tamanho = arrayCaminhoImagen.length;
	const nomeComposto = arrayCaminhoImagen[tamanho - 1];
	const nomeImagem = nomeComposto.split(".")[0];

	return (
		<Container>
			<LinkContainer>
				<Link>Saiba mais</Link>
				<i className="bi bi-arrow-right-circle-fill text-success"></i>
			</LinkContainer>
			<ImagemContainer>
				<Imagem src={imagem} alt={nomeImagem} />
			</ImagemContainer>
			<TextContainer>
				<Titulo>{titulo}</Titulo>
				<Subtitulo>{subtitulo}</Subtitulo>
			</TextContainer>
		</Container>
	);
}
