// Componentes personalizados
import Form from "../../components/Form";
import BannerNavegacao from "../../components/BannerNavegacao";
import BlocoTitulo from "../../components/BlocoTituloTextoBotaoImagem";
import { BotaoFaleConosco, BotaoEnviarMensagem } from "../../components/Botoes";
import SecaoPagina from "../../components/SecaoPagina";
import PageLayout from "../../layouts/PageLayout";
// Imagens
import ImagemMulherCelularHomem from "../../assets/imagens/MulherCelularHomem.svg";
import FundoVerde from "../../assets/imagens/FundoVerdeGrafo.webp";

export default function PaginaFaleConosco() {
	return (
		<PageLayout>
			<BannerNavegacao pagina={"Fale conosco"} />

			<SecaoPagina>
				<BlocoTitulo
					margemImagem={null}
					corParagrafo={null}
					corTitulo={null}
					tituloTipoB={false}
					imagem={ImagemMulherCelularHomem}
					possuiLogosClientes={false}
					botao1={<BotaoFaleConosco />}
					botao2={<BotaoEnviarMensagem />}
					paragrafo1={
						"Na Sifat, oferecemos a solução ideal para impulsionar o seu empreendimento, seja através de nossas soluções em nuvem, aplicativos ou parcerias como Integrador."
					}
					paragrafo2={
						"Se você deseja receber mais informações sobre como se tornar um Parceiro/Integrador, ou simplesmente deseja compartilhar sugestões ou comentários, estamos aqui para ajudar."
					}
					tituloTipoA={true}
					titulo1={"Fale com a"}
					titulo2={"Sifat!"}
				/>
			</SecaoPagina>

			<SecaoPagina
				$paddingmin={"2em 2em 9em"}
				$paddingmid={"2em 2em 9em"}
				$paddingmax={"4em 4em 8em"}
				$bgimagem={FundoVerde}
				id={"formFale"}
			>
				<Form
					titulo={"Fale conosco"}
					subtitulo={
						"Não perca tempo! Preencha o formulário abaixo e um dos nossos especialistas entrará em contato em breve para fornecer toda a assistência necessária. Juntos, vamos levar o seu negócio para o próximo nível!"
					}
					trabalheConosco={false}
					color={"white"}
				/>
			</SecaoPagina>
		</PageLayout>
	);
}
