import styled from "styled-components";

const Section = styled.section`
	padding: ${(props) => props.$paddingmin || "2em 1em"};
	display: flex;
	flex-direction: column;
	gap: ${(props) => props.$gap || "1em"};
	margin-top: ${(props) => props.$margintop || ""};
	margin-bottom: ${(props) => props.$marginbottom || ""};
	align-items: center;
	width: 100%;
	max-width: var(--max-section-w);

	@media screen and (min-width: 350px) {
		padding: ${(props) => props.$paddingmid || "2em"};
	}

	@media screen and (min-width: 993px) {
		padding: ${(props) => props.$paddingmax || "4em"};
	}
`;

const Fundo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${(props) => props.$bgcolor || ""};
	background-image: url(${(props) => (props.$bgimagem ? props.$bgimagem : "")});
	background-position-x: ${(props) => props.$position || "center"};
	background-size: ${(props) => props.$bgsize || "cover"};
	background: ${(props) => props.$bg || ""};

	@media screen and (min-width: 993px) {
		background-position-x: right;
        background-repeat: no-repeat;
        background-size: cover;
	}
`;

export default function SecaoPagina(props) {
	return (
		<Fundo
			$bgimagem={props.$bgimagem}
			$bgsize={props.$bgsize}
			$bgcolor={props.$bgcolor}
			$bg={props.$bg}
			className="section"
			id={props.id}
		>
			<Section
				$paddingmin={props.$paddingmin}
				$paddingmid={props.$paddingmid}
				$paddingmax={props.$paddingmax}
				$isdesktop={props.$isdesktop}
				$gap={props.$gap}
				$position={props.$position}
				$margintop={props.$margintop}
				$marginbottom={props.$marginbottom}
				data-aos="zoom-in-down"
				data-aos-duration="1500"
			>
				{props.children}
			</Section>
		</Fundo>
	);
}
