import styled from "styled-components";
import CardTecnologiaOferecida from "../CardTecnologiaOferecida";

// Imagens tecnologias
import Cardapio from "../../assets/imagens/imagensTecnologias/Cardapio.webp";
import Catraca from "../../assets/imagens/imagensTecnologias/Catraca.webp";
import ERPmobile from "../../assets/imagens/imagensTecnologias/ERPmobile.webp";
import EntradaSaida from "../../assets/imagens/imagensTecnologias/EntradaSaida.webp";
import Lumien from "../../assets/imagens/imagensTecnologias/Lumien.webp";
import SmartPOS from "../../assets/imagens/imagensTecnologias/SmartPOS.webp";
import Wayservice from "../../assets/imagens/imagensTecnologias/Wayservice.webp";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1em;
`;

const TextoContainer = styled.div``;

const Titulo = styled.h1`
	text-align: left;
	font-weight: var(--strong-weight);
	font-size: var(--bigger-font);
`;

const Subtitulo = styled.h2`
	text-align: left;
	font-weight: var(--weak-weight);
	font-size: var(--small-font);
`;

const CardsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.5em;

	@media screen and (min-width: 650px) {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-around;
	}
`;

export default function TecnologiasOferecidas() {
	return (
		<Container>
			<TextoContainer>
				<Titulo>
					Oferecemos todas as{" "}
					<span style={{ color: "#1B5EA4" }}>tecnologias de gestão</span> que
					você está buscando.
				</Titulo>
				<Subtitulo>
					Transforme os problemas do seu negócio em oportunidades de crescimento
					com as nossas soluções de tecnologia. Tudo centralizado em uma única
					empresa para facilitar para você!
				</Subtitulo>
			</TextoContainer>

			<CardsContainer>
				<CardTecnologiaOferecida
					titulo={
						"Cardápio digital que funciona com delivery e QR code na mesa"
					}
					subtitulo={
						"Controle as entradas e saídas da sua padaria ou conveniência de um jeito simples e automatizado!"
					}
					imagem={Cardapio}
				/>

				<CardTecnologiaOferecida
					titulo={
						"Diminua filas e aumente o ticket médio. Conheça o Wayservice"
					}
					subtitulo={
						"Controle as entradas e saídas da sua padaria ou conveniência de um jeito simples e automatizado!"
					}
					imagem={Wayservice}
				/>

				<CardTecnologiaOferecida
					titulo={"Agilize seus pedidos com o PDV nas maquininhas SmartPOS"}
					subtitulo={
						"Controle as entradas e saídas da sua padaria ou conveniência de um jeito simples e automatizado!"
					}
					imagem={SmartPOS}
				/>

				<CardTecnologiaOferecida
					titulo={
						"Você pode controlar o seu estoque em tempo real com o ERP mobile"
					}
					subtitulo={
						"Controle as entradas e saídas da sua padaria ou conveniência de um jeito simples e automatizado!"
					}
					imagem={ERPmobile}
				/>

				<CardTecnologiaOferecida
					titulo={"Automação de bombas e gestão por turnos com o Lumien PDV"}
					subtitulo={
						"Controle as entradas e saídas da sua padaria ou conveniência de um jeito simples e automatizado!"
					}
					imagem={Lumien}
				/>

				<CardTecnologiaOferecida
					titulo={"Economize com mão de obra e agilize as filas do seu mercado"}
					subtitulo={
						"Controle as entradas e saídas da sua padaria ou conveniência de um jeito simples e automatizado!"
					}
					imagem={EntradaSaida}
				/>

				<CardTecnologiaOferecida
					titulo={"Autopagamento integrado com catraca"}
					subtitulo={
						"Controle as entradas e saídas da sua padaria ou conveniência de um jeito simples e automatizado! "
					}
					imagem={Catraca}
				/>
			</CardsContainer>
		</Container>
	);
}
