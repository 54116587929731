import styled from "styled-components";
import { useEffect } from "react";
// Componentes personalizados
import BlocoTituloTextoBotaoImagem from "../../components/BlocoTituloTextoBotaoImagem";
import { BotaoFaleConosco, BotaoReceberLigacao } from "../../components/Botoes";
import { PorqueProdutoFlutt } from "../../components/PorqueProduto";
import FuncoesWaychef from "../../components/FuncoesWaychef";
import { TituloFeaturesTextoFlutt } from "../../components/TituloFeaturesTexto";
import { DuvidasFlutt } from "../../components/Duvidas";
import Form from "../../components/Form";
import SecaoPagina from "../../components/SecaoPagina";
import PageLayout from "../../layouts/PageLayout";
// Imagens
import LogoWaychefTransparente from "../../assets/imagens/LogoWaychefBrancoTransparente.png";
import FundoLaranjaWaychefTelas from "../../assets/imagens/FundoLaranjaWaychefTelas.webp";
import FundoLaranjaDiner from "../../assets/imagens/waychef/FundoLaranjaDiner.jpg";
import FundoLaranja from "../../assets/imagens/waychef/FundoLaranjaS.jpg";
import TelasAplicacao from "../../assets/imagens/waychef/TelasAplicacao.webp";

const LogoWaychef = styled.img`
	width: 10em;
	height: auto;

	@media screen and (min-width: 993px) {
		margin-right: auto;
	}
`;

export default function PageEfex() {
	useEffect(() => {
		require("bootstrap/dist/js/bootstrap.min.js");
	}, []);

	return (
		<PageLayout>
			<SecaoPagina
				$paddingmin={"2em"}
				$bgimagem={FundoLaranjaWaychefTelas}
				$position={"left"}
			>
				<LogoWaychef src={LogoWaychefTransparente} alt={"Logo Waychef"} />

				<BlocoTituloTextoBotaoImagem
					imagem={TelasAplicacao}
					margemImagem={null}
					corTitulo={null}
					paragrafo2={null}
					tituloTipoA={null}
					titulo2={null}
					possuiLogosClientes={false}
					botao1={<BotaoFaleConosco />}
					botao2={<BotaoReceberLigacao />}
					corParagrafo={"white"}
					paragrafo1={
						"Com o Flutt, o seu negócio varejista se destaca! Oferecemos um sistema completo, com ferramentas diferenciadas e específicas para impulsionar o seu sucesso."
					}
					tituloTipoB={true}
					titulo1={
						"PDV + ERP completo para lojas de varejo"
					}
				/>
			</SecaoPagina>

			<SecaoPagina $bgcolor={"#EFEFEF"} $paddingmin={"2em"}>
				<TituloFeaturesTextoFlutt />
			</SecaoPagina>

			<SecaoPagina $bgcolor={"#EFEFEF"} $paddingmin={"2em"}>
				<PorqueProdutoFlutt />
			</SecaoPagina>

			{/* Falta fazer funções! A ferramenta indispensável para o sucesso do seu negócio varejista! */}
			<SecaoPagina $bgimagem={FundoLaranjaDiner} $paddingmin={"2em"}>
				<FuncoesWaychef />
			</SecaoPagina>

			<SecaoPagina $bgimagem={FundoLaranja} $paddingmin={"2em"}>
				<DuvidasFlutt />
			</SecaoPagina>

			<SecaoPagina
				$bg={"#EFEFEF"}
				$paddingmin={"2em 2em 9em"}
				$paddingmid={"2em 2em 9em"}
				$paddingmax={"4em 4em 8em"}
				id={"formLigacao"}
			>
				<Form
					formProduto={true}
					titulo={"Nosso time liga para você!"}
					subtitulo={
						"Pronto para começar? Preencha o formulário abaixo e o nosso time de consultores entrará em contato com você."
					}
					trabalheConosco={false}
					color={"black"}
					$bgcolor={"#F26A35"}
				/>
			</SecaoPagina>
		</PageLayout>
	);
}
