import styled from "styled-components";

const Button = styled.button`
	color: ${(props) => props.color || "white"};
	background-color: ${(props) => props.$backgroundcolor || "#FFFFFF"};
	padding: ${(props) => props.$padding || "1.2em 2em"};
	border: none;
	border-radius: ${(props) => props.$bigradius || "var(--border-radius-m);"};
	font-size: ${(props) => props.fontSize || "var(--small-font)"};
	font-weight: var(--weak-weight);
	display: flex;
	height: ${(props) => props.height || "2em"};
	align-items: center;
	align-self: ${(props) => props.$alignself || "unset"};
	justify-content: center;
	flex-grow: ${(props) => props.$grow || "1"};
	width: ${(props) => props.$width || "unset"};

	@media screen and (min-width: 993px) {
		flex-grow: unset;
	}
`;

function WhatsAppLogo() {
	return <i className="bi bi-whatsapp mx-1"></i>;
}

export default function BotaoModelo({
	$backgroundcolor,
	$padding,
	$bigradius,
	$width,
	$grow,
	$alignself,
	texto,
	temIcone,
	height,
	fontSize,
	color,
	hrefUrl = null,
	customOnClick,
}) {
	return (
		<>
			<Button
				$backgroundcolor={$backgroundcolor}
				$padding={$padding}
				$bigradius={$bigradius}
				$grow={$grow}
				$width={$width}
				height={height}
				fontSize={fontSize}
				color={color}
				$alignself={$alignself}
				onClick={() => {
					customOnClick(hrefUrl);
				}}
			>
				{temIcone ? <WhatsAppLogo /> : null}
				{texto}
			</Button>
		</>
	);
}
