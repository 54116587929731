import styled from "styled-components";
import ListaParceiros from "../ListaParceiros";

const ImagemMobile = styled.img`
	display: none;

	@media screen and (max-width: 650px) {
		margin: ${(props) => props.$margem || "1em 0"};
		width: 100%;
		height: auto;
		max-width: 25em;
		display: ${(props) => (props.$possuiImagem ? "flex" : "none")};
		align-self: center;
	}
`;

const Imagem = styled.img`
	display: none;

	@media screen and (min-width: 651px) and (max-width: 992px) {
		margin: ${(props) => props.$margem || "1em 0"};
		width: 100%;
		height: auto;
		max-width: 25em;
		display: ${(props) => (props.$possuiImagem ? "flex" : "none")};
		align-self: center;
	}
`;

const ImagemDesktop = styled.img`
	display: none;

	@media screen and (min-width: 993px) {
		width: 100%;
		height: auto;
		max-width: 25em;
		display: ${(props) => (props.$possuiImagem ? "flex" : "none")};
		align-self: end;
	}
`;

const Container = styled.div`
	display: flex;

	@media screen and (min-width: 993px) {
		flex-direction: row;
		width: 100%;
		align-items: center;
	}
`;

const ContainerTexto = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 100%;

	@media screen and (min-width: 993px) {
		width: 50%;
	}
`;

const ContainerImagem = styled.div`
	display: none;

	@media screen and (min-width: 993px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 50%;
	}
`;

const ParceirosWrapperOuter = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1em;
	margin-top: 2em;

	@media screen and (min-width: 993px) {
		margin-top: auto;
	}
`;

const Titulo = styled.h1`
	font-weight: var(--strong-weight);
	text-align: left;
	color: ${(props) => props.$corTitulo || "black"};
	font-size: var(--biggest-font);

	@media screen and (max-width: 600px) {
		font-size: var(--big-font);
	}
`;

const Subtitulo = styled.h2`
	font-size: var(--small-font);
	text-align: center;
	font-weight: var(--strong-weight);
	margin: 2em 1em 0;

	@media screen and (min-width: 993px) {
		text-align: start;
	}
`;

const BotoesWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 1.2em;
	justify-content: center;

	@media screen and (min-width: 993px) {
		justify-content: start;
	}
`;

const Paragrafo = styled.p`
	text-align: left;
	color: ${(props) => props.$corParagrafo || "black"};
	font-weight: var(--regular-weight);
	font-size: var(--medium-font);
`;

function LogosClientes() {
	return (
		<>
			<ParceirosWrapperOuter>
				<Subtitulo>Nossos parceiros</Subtitulo>
				<ListaParceiros />
			</ParceirosWrapperOuter>
		</>
	);
}

export default function BlocoTituloTextoBotaoImagem({
	imagem,
	margemImagem,
	possuiLogosClientes,
	botao1,
	botao2,
	corParagrafo,
	corTitulo,
	paragrafo1,
	paragrafo2,
	tituloTipoA, // preto e azul
	tituloTipoB, // branco
	titulo1,
	titulo2,
}) {
	return (
		<Container>
			<ContainerTexto>
				{tituloTipoA && (
					<Titulo $corTitulo={corTitulo}>
						{titulo1} <span style={{ color: "#1B5EA4" }}>{titulo2}</span>
					</Titulo>
				)}
				{tituloTipoB && (
					<Titulo $corTitulo={corTitulo}>
						<span style={{ color: "white" }}>{titulo1}</span>
					</Titulo>
				)}

				{imagem && (
					<ImagemMobile
						$possuiImagem={imagem}
						src={imagem}
						$margem={margemImagem}
						alt="Imagem do título"
					/>
				)}

				<Paragrafo $corParagrafo={corParagrafo}>
					{paragrafo1}
					{paragrafo2 && <> <br></br>{paragrafo2}</>}
				</Paragrafo>

				{imagem && (
					<Imagem
						$possuiImagem={imagem}
						src={imagem}
						$margem={margemImagem}
						alt="Imagem do título"
					/>
				)}

				<BotoesWrapper>
					{botao1}
					{botao2 && botao2}
				</BotoesWrapper>
				{possuiLogosClientes && <LogosClientes />}
			</ContainerTexto>

			<ContainerImagem>
				{imagem && (
					<ImagemDesktop
						$possuiImagem={imagem}
						src={imagem}
						$margem={margemImagem}
						alt="Imagem do título"
					/>
				)}
			</ContainerImagem>
		</Container>
	);
}
