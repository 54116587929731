import styled from "styled-components";
import PageLayout from "../../layouts/PageLayout";
import SecaoPagina from "../../components/SecaoPagina";

const Incisos = styled.div``;

const Titulo = styled.h1`
	font-size: var(--bigger-font);
	font-weight: var(--strong-weight);
`;

const Subtitulo = styled.h2`
	font-size: var(--big-font);
	font-weight: var(--regular-weight);
`;

const Paragrafo = styled.div`
	font-size: var(--small-font);
	font-weight: var(--weak-weight);
	width: 100%;
	margin: 2em 0;
`;

const Lista = styled.ol`
	list-style-type: ${(props) => props.$type || "none"};
	padding-left: ${(props) => props.$padding || "2em"};
	margin: 0;
`;

const Item = styled.li``;

export default function PaginaPrivacidade() {
	return (
		<PageLayout>
			<SecaoPagina
				$bgcolor={"#f8f9fa"}
				$paddingmin={"2em 2em 9em"}
				$paddingmid={"2em 2em 9em"}
				$paddingmax={"4em 4em 8em"}
			>
				<Titulo>TERMO DE USO E POLÍTICA DE PRIVACIDADE DO “SIFAT”</Titulo>
				<Paragrafo>
					<Lista $padding={"0"}>
						<Item>“SIFAT” coleta alguns dados pessoais de seus usuários.</Item>
						<Item>Proprietário e Controlador de Dados: Sifat Sistemas.</Item>
						<Item>E-mail de contato do proprietário: mkt@sifat.com.br</Item>
					</Lista>
				</Paragrafo>
				<Incisos>
					<Subtitulo>I. Tipos de dados coletados</Subtitulo>
					<Paragrafo>
						Entre os tipos de Dados Pessoais que o “SIFAT” coleta, por si ou por
						meio de terceiros, estão: Cookies, Dados de Uso, vários tipos de
						Dados, como: endereço de e-mail, nome, sobrenome, telefone, dados
						financeiros declarados, RG, CPF, informações relacionadas a operação
						do cliente, como por exemplo: produtos e/ou serviços ofertados,
						preços, formas de pagamento, vendas realizadas, dentre outras
						informações. Detalhes completos sobre cada tipo de Dados Pessoais
						coletados são fornecidos nas seções dedicadas desta política de
						privacidade ou por textos explicativos específicos exibidos antes da
						coleta de Dados. Os Dados Pessoais podem ser fornecidos livremente
						pelo Usuário ou, no caso de Dados de Uso, coletados automaticamente
						ao usar o “SIFAT”. A menos que especificado de outra forma, todos os
						dados solicitados pelo “SIFAT” são obrigatórios e a falha em
						fornecer esses dados pode impossibilitar que a “SIFAT” forneça seus
						produtos e/ou serviços. Nos casos em que o “SIFAT” declara
						especificamente que alguns Dados não são obrigatórios, os Usuários
						são livres para não comunicar esses Dados sem consequências para a
						disponibilidade ou o funcionamento do Serviço. Os usuários que não
						tiverem certeza sobre quais dados pessoais são obrigatórios, entre
						em contato com o proprietário. Qualquer uso de cookies – ou de
						outras ferramentas de rastreamento – pelo “SIFAT” ou pelos
						proprietários de serviços de terceiros usados pela “SIFAT” atende à
						finalidade de fornecer o Serviço exigido pelo Usuário, além de
						quaisquer outros fins descritos no presente documento. Os usuários
						são responsáveis por quaisquer dados pessoais de terceiros obtidos,
						publicados ou compartilhados por meio do “SIFAT” e confirmam que
						eles têm o consentimento de terceiros para fornecer os dados ao
						proprietário.
					</Paragrafo>
					<Subtitulo>II. Formas de utilização dos dados coletados</Subtitulo>
					<Paragrafo>
						Os dados obtidos são usados pelo “SIFAT” para:
						<Lista>
							<Item>
								a) Viabilizar o fornecimento e aprimoramento dos nossos
								serviços;
							</Item>
							<Item>
								b) Viabilizar o recebimento dos pagamentos devidos pelos
								serviços contratados;
							</Item>
							<Item>c) Verificar a identidade dos clientes;</Item>
							<Item>d) Solucionar problemas;</Item>
							<Item>
								e) Gerenciar riscos ou tentar detectar, evitar e/ou remediar
								fraudes ou outras atividades potencialmente proibidas ou
								ilegais;
							</Item>
							<Item>
								f) Detectar, evitar ou remediar violações de políticas ou
								contratos aplicáveis;
							</Item>
							<Item>
								g) Gerir e proteger nossa infraestrutura de tecnologia da
								informação;
							</Item>
							<Item>
								h) Fornecer publicidade e marketing direcionados, notificações
								de atualização de serviços e enviar ofertas promocionais com
								base nas suas preferências de comunicação;
							</Item>
							<Item>
								i) Realizar verificações de crédito e solvência, além de
								comparar informações para maior precisão e verifica-las com
								terceiros;
							</Item>
							<Item>j) Cumprir determinações legais e fiscais.</Item>
						</Lista>
					</Paragrafo>
					<Subtitulo>III. Compartilhamento de informações</Subtitulo>
					<Paragrafo>
						<Lista>
							<Item>
								a) A “SIFAT poderá compartilhar dados coletados com empresas do
								grupo e parceiros com a finalidade de realizar a prestação de
								serviço, como exemplo: empresas de pagamento online,
								marketplaces e transportadoras;
							</Item>
							<Item>
								b) Os parceiros legais da “SIFAT” se obrigam a respeitar a
								presente política quanto as informações por eles recebidas.
							</Item>
							<Item>
								c) O “SIFAT” não vende nem aluga as informações pessoais do
								usuário para terceiros para fins de marketing, bem como, atua
								fortemente na preservação dos dados, de sua confidencialidade,
								através de sua política interna de segurança da informação;
							</Item>
							<Item>
								d) O “SIFAT” respeita todas as diretrizes da Lei Geral de
								Proteção de Dados e do Marco Civil da Internet, sendo certo que
								não divulga as informações pessoais do usuário a terceiros,
								ressalvado o disposta acima e os casos de ordem judicial.
							</Item>
						</Lista>
					</Paragrafo>
					<Subtitulo>
						IV. Formas de armazenamento e proteção das informações
					</Subtitulo>
					<Paragrafo>
						<Lista>
							<Item>
								a) As informações obtidas são protegidas com medidas físicas,
								técnicas e administrativas de segurança para reduzir riscos de
								perda, mau uso e acesso não autorizado, divulgação e alteração,
								tais como firewalls e criptografia de dados, controles de acesso
								físico a data centers, além de controles de autorização de
								acesso à informação;
							</Item>
						</Lista>
					</Paragrafo>
					<Subtitulo>V. Tempo de retenção dos dados</Subtitulo>
					<Paragrafo>
						Os Dados Pessoais devem ser processados e armazenados pelo tempo
						requerido pelo propósito para o qual foram coletados. Assim sendo:
						<Lista $type={"disc"}>
							<Item>
								Os Dados Pessoais coletados para fins relacionados ao
								cumprimento de um contrato entre o Proprietário e o Usuário
								serão retidos até que tal contrato tenha sido totalmente
								executado.
							</Item>
							<Item>
								Os Dados Pessoais coletados para os propósitos dos interesses
								legítimos do Proprietário serão retidos pelo tempo necessário
								para cumprir tais propósitos. Os usuários podem encontrar
								informações específicas sobre os interesses legítimos do
								Proprietário nas seções relevantes deste documento ou entrar em
								contato com o proprietário.
							</Item>
						</Lista>
						O Proprietário pode ser autorizado a reter Dados Pessoais por um
						período mais longo sempre que o Usuário der consentimento para tal
						processamento, desde que tal consentimento não seja retirado. Além
						disso, o proprietário pode ser obrigado a reter dados pessoais por
						um período mais longo sempre que necessário para o desempenho de uma
						obrigação legal ou por ordem de uma autoridade. Quando o período de
						retenção expirar, os Dados Pessoais serão excluídos. Portanto, o
						direito de acesso, o direito de apagar, o direito de retificação e o
						direito à portabilidade de dados não podem ser cumpridos após a
						expiração do período de retenção.
					</Paragrafo>
					<Subtitulo>VI. Direitos dos usuários</Subtitulo>
					<Paragrafo>
						Em particular, os usuários têm o direito de fazer o seguinte:
						<Lista $type={"disc"}>
							<Item>
								Retire seu consentimento a qualquer momento. Os usuários têm o
								direito de retirar o consentimento, desde que tenham dado seu
								consentimento para o processamento de seus dados pessoais.
							</Item>
							<Item>
								Opor-se ao processamento de seus dados. Os usuários têm o
								direito de se opor ao processamento de seus dados se o
								processamento for realizado em uma base legal que não seja o
								consentimento. Mais detalhes são fornecidos na seção dedicada
								abaixo.
							</Item>
							<Item>
								Desabilitar cookies. Os usuários têm o direito de desabilitar os
								cookies de seu navegador ou add-on permitir, salvo nos casos nos
								quais os cookies sejam necessários para a garantia da qualidade
								de prestação de serviços. Lembrando que a recusa dos cookies
								pode interferir no uso do “SIFAT”.
							</Item>
							<Item>
								Verificar e buscar retificação. Os usuários têm o direito de
								verificar a exatidão de seus dados e solicitar que sejam
								atualizados ou corrigidos.
							</Item>
							<Item>
								Restringir o processamento de seus dados. Os usuários têm o
								direito, sob determinadas circunstâncias, de restringir o
								processamento de seus dados. Nesse caso, o proprietário não
								processará seus dados para qualquer finalidade que não seja
								armazená-los.
							</Item>
							<Item>
								Ter seus dados pessoais excluídos ou removidos. Os usuários têm
								o direito, sob determinadas circunstâncias, de obter o
								apagamento de seus dados do proprietário. Lembrando que o
								“SIFAT” deve respeitar o tempo de armazenamento de dados
								determinado pela legislação, para o cumprimento de obrigações
								legais e fiscais.
							</Item>
							<Item>
								Apresentar uma queixa. Os usuários têm o direito de apresentar
								uma reclamação perante sua autoridade competente de proteção de
								dados.
							</Item>
						</Lista>
						Os usuários devem saber que, no entanto, caso seus dados pessoais
						sejam processados para fins de marketing direto, eles podem se opor
						a esse processamento a qualquer momento sem fornecer qualquer
						justificativa. Para saber se o proprietário está processando dados
						pessoais para fins de marketing direto, os usuários podem consultar
						as seções relevantes deste documento. Como exercer esses direitos
						Quaisquer solicitações para exercer direitos de usuário podem ser
						direcionadas ao proprietário através dos detalhes de contato
						fornecidos neste documento. Estes pedidos podem ser exercidos
						gratuitamente e serão endereçados pelo Proprietário o mais cedo
						possível e sempre dentro de um mês.
					</Paragrafo>
					<Subtitulo>VII. Modo e local de processamento dos dados</Subtitulo>
					<Paragrafo>
						Métodos de processamento. O proprietário toma medidas de segurança
						apropriadas para impedir o acesso não autorizado, divulgação,
						modificação ou destruição não autorizada dos dados. O processamento
						de dados é realizado por meio de computadores e / ou ferramentas de
						TI, seguindo procedimentos e modos organizacionais estritamente
						relacionados às finalidades indicadas. Além do Proprietário, em
						alguns casos, os Dados podem ser acessíveis a certos tipos de
						pessoas responsáveis, envolvidos com a operação do Aplicativo
						(administração, vendas, marketing, jurídico, administração do
						sistema) ou externos (como terceiros), provedores de serviços
						técnicos, operadoras de correspondências, provedores de hospedagem,
						empresas de TI, agências de comunicação) designados, se necessário,
						como Processadores de Dados pelo Proprietário. A lista atualizada
						dessas partes pode ser solicitada ao Proprietário a qualquer
						momento.
					</Paragrafo>
					<Subtitulo>VIII. Base jurídica do processamento</Subtitulo>
					<Paragrafo>
						O proprietário pode processar dados pessoais relacionados a usuários
						se um dos seguintes itens se aplicar:
						<Lista $type={"disc"}>
							<Item>
								Os usuários deram seu consentimento para um ou mais propósitos
								específicos. Nota: Sob algumas legislações, o Proprietário pode
								ter permissão para processar Dados Pessoais até que o Usuário
								oponha-se a tal processamento (“opt-out”), sem ter que depender
								do consentimento ou de qualquer outra das seguintes bases
								legais. Isto, no entanto, não se aplica, sempre que o
								processamento de Dados Pessoais esteja sujeito à lei de proteção
								de dados.
							</Item>
							<Item>
								O fornecimento de dados é necessário para a execução de um
								contrato com o usuário e/ou para quaisquer obrigações
								pré-contratuais do mesmo.
							</Item>
							<Item>
								O processamento é necessário para o cumprimento de uma obrigação
								legal a qual o Proprietário está sujeito.
							</Item>
							<Item>
								O processamento é necessário para os interesses legítimos da
								Proprietária ou de terceiros.
							</Item>
						</Lista>
						Em qualquer caso, o Proprietário ajudará de bom grado a esclarecer a
						base legal específica que se aplica ao processamento e, em
						particular, se o fornecimento de Dados Pessoais é uma exigência
						legal ou contratual, ou um requisito necessário para celebrar um
						contrato.
					</Paragrafo>
					<Subtitulo>IX. Local</Subtitulo>
					<Paragrafo>
						Os dados são processados nos escritórios operacionais do
						proprietário e em qualquer outro local onde as partes envolvidas no
						processamento estejam localizadas. Dependendo da localização do
						usuário, as transferências de dados podem envolver a transferência
						dos dados do usuário para um país diferente do seu. Para saber mais
						sobre o local de processamento de tais dados transferidos, os
						usuários podem verificar a seção que contém detalhes sobre o
						processamento de dados pessoais. Se tal transferência ocorrer, os
						Usuários poderão obter mais informações, verificando as seções
						relevantes deste documento ou consultando o Proprietário, usando as
						informações fornecidas na seção de contato.
					</Paragrafo>
					<Subtitulo>
						X. Informações adicionais sobre os dados pessoais do usuário
					</Subtitulo>
					<Paragrafo>
						Além das informações contidas nesta política de privacidade, o
						“SIFAT” pode fornecer ao usuário informações adicionais e
						contextuais sobre determinados serviços ou a coleta e processamento
						de dados pessoais mediante solicitação.
					</Paragrafo>
					<Subtitulo>XI. Logs e manutenção do sistema</Subtitulo>
					<Paragrafo>
						Para fins de operação e manutenção, o “SIFAT” e quaisquer serviços
						de terceiros podem coletar arquivos que registram a interação com o
						“SIFAT (registros do sistema) usando outros dados pessoais (como o
						endereço IP) para essa finalidade.
					</Paragrafo>
					<Subtitulo>XII. Informações não contidas nesta política</Subtitulo>
					<Paragrafo>
						Mais detalhes sobre a coleta ou processamento de dados pessoais
						podem ser solicitados ao Proprietário a qualquer momento.
					</Paragrafo>
					<Subtitulo>XIII. Alterações a esta política de privacidade</Subtitulo>
					<Paragrafo>
						O proprietário se reserva o direito de fazer alterações a esta
						política de privacidade a qualquer momento, notificando seus
						usuários nesta página e possivelmente dentro do próprio usuário e/ou
						– na medida do possível técnica e legalmente – enviando um aviso aos
						usuários através de qualquer informação de contato disponível para o
						proprietário. É altamente recomendável verificar essa página com
						frequência, consultando a data da última modificação listada na
						parte inferior. Se as alterações afetarem as atividades de
						processamento realizadas com base no consentimento do usuário, o
						proprietário deverá coletar novo consentimento do usuário, quando
						necessário. Esta política de privacidade refere-se unicamente ao
						“SIFAT”, se não indicado de outra forma neste documento.
					</Paragrafo>
				</Incisos>
				<Paragrafo>Última atualização: 09/04/2021</Paragrafo>
			</SecaoPagina>
		</PageLayout>
	);
}
