import styled from "styled-components";
import BlocoTituloTextoBotaoImagem from "../BlocoTituloTextoBotaoImagem";
import { BotaoPlanosPrecos } from "../Botoes";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
// Imagens
import FeatureDelivery from "../../assets/imagens/waychef/FeatureDelivery.webp";
import FeatureExperienciaDoCliente from "../../assets/imagens/waychef/FeatureExperienciaDoCliente.webp";
import FeatureFiscalFinanceiro from "../../assets/imagens/waychef/FeatureFiscalFinanceiro.webp";
import FeaturePontoDeVenda from "../../assets/imagens/waychef/FeaturePontoDeVenda.webp";
import FeatureRetaguarda from "../../assets/imagens/waychef/FeatureRetaguarda.webp";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1.5em;
	max-width: 100%;
`;

const TituloContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const Titulo = styled.h1`
	text-align: center;
	color: #f26a35;
	font-weight: var(--strong-weight);
	font-size: var(--bigger-font);
`;

const Subtitulo = styled.h2`
	text-align: center;
	font-weight: var(--weak-weight);
	font-size: var(--small-font);
`;

const FeaturesContainer = styled(Tabs)`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	gap: 0.3em;
`;

const Feature = styled(Tab)`
	padding: 0.5em 0.5em;
	background-color: transparent;
	color: #f26a35;
	border: 2px solid #f26a35;
	border-radius: var(--border-radius-m);
	width: 6em;
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-weight: var(--regular-weight);
	font-size: var(--smaller-font);
	height: 3em;
`;

const BlocoWrapper = styled.div`
	padding: 0.6em 0.3em;
	display: flex;
	flex-direction: column;
`;

export function TituloFeaturesTextoWaychef() {
	return (
		<Container>
			<TituloContainer>
				<Titulo>Seu restaurante ganha mais com o Waychef</Titulo>
				<Subtitulo>
					Conquiste e fidelize mais clientes com os nossos módulos de gestão
					para Food Service.
				</Subtitulo>
			</TituloContainer>
			<FeaturesContainer defaultActiveKey="Retaguarda" id="navigationTabs">
				<Feature eventKey="Retaguarda" title="Retaguarda">
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeatureRetaguarda}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"Nosso sistema ERP é online, acessível de qualquer navegador da internet. Você pode fazer o controle de clientes, produtos e fornecedores de forma simples, tudo no mesmo lugar. Integra-se perfeitamente aos principais serviços de delivery do mercado e possui integração nativa com o WayChef PDV, possibilitando que você acompanhe sua loja em tempo real! "
							}
							tituloTipoA={true}
							titulo1={
								"Coordene seu empreendimento por meio da integração entre o sistema retaguarda e o PDV"
							}
						/>
					</BlocoWrapper>
				</Feature>
				<Feature eventKey="Ponto-de-Venda" title="Ponto de Venda">
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeaturePontoDeVenda}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"O WayChef oferece um PDV completo, com um aplicativo nativo que pode ser usado em pontos fixos e smartphones, permitindo o uso pelos atendentes em qualquer lugar. O PDV conta com módulos para atendimento no balcão, drive-thru, ficha, mesa e até delivery. A interface é fácil de usar e possui muitas ferramentas para facilitar o seu dia a dia."
							}
							tituloTipoA={true}
							titulo1={"Experimente o PDV que torna sua gestão mais eficiente"}
						/>
					</BlocoWrapper>
				</Feature>
				<Feature eventKey="Delivery" title="Delivery">
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeatureDelivery}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"O WayChef apresenta uma solução completa para o setor de delivery, integrando-se ao iFood e ao cardápio digital WayMenu. Nosso PDV permite o cadastro detalhado de clientes, facilitando sua operação. Além disso, simplifica o processo de cadastro e pagamento/recebimento de vendedores, proporcionando mais agilidade e controle."
							}
							tituloTipoA={true}
							titulo1={"Aprimore sua Operação de Delivery com o WayChef PDV"}
						/>
					</BlocoWrapper>
				</Feature>
				<Feature eventKey="Fiscal-e-Financeiro" title="Fiscal e Financeiro">
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeatureFiscalFinanceiro}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"Com as funções financeiras e fiscais você pode: controlar cartões, fazer análises gerenciais, movimentações e gerir o centro de custos de forma integrada e intuitiva. Mantenha suas finanças organizadas com um plano de contas eficiente. Na área fiscal, consulte e emita NF-e/NFS-e diretamente pelo sistema, que também facilita a emissão de notas fiscais."
							}
							tituloTipoA={true}
							titulo1={
								"Nosso ERP atende às suas necessidades em gestão financeira e controle fiscal"
							}
						/>
					</BlocoWrapper>
				</Feature>
				<Feature
					eventKey="Fidelize-seu-cliente"
					title="Fidelize seu Cliente"
				>
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeatureExperienciaDoCliente}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"Com o aplicativo Waychef PDV na maquininha, seus garçons podem registrar pedidos e enviá-los diretamente para a cozinha, agilizando o atendimento. Você também pode cadastrar os dados básicos do seu cliente para agilizar o atendimento de deliveries, criar promoções personalizadas e estratégias com Cashback para aumentar a recorrência na sua loja."
							}
							tituloTipoA={true}
							titulo1={"Fidelize os seus clientes com as nossas ferramentas"}
						/>
					</BlocoWrapper>
				</Feature>
			</FeaturesContainer>
		</Container>
	);
}

export function TituloFeaturesTextoEfex() {
	return (
		<Container>
			<TituloContainer>
				<Titulo>Impulsione a operação do seu supermercado com o Efex</Titulo>
				<Subtitulo></Subtitulo>
			</TituloContainer>
			<FeaturesContainer defaultActiveKey="Retaguarda" id="navigationTabs">
				<Feature eventKey="Retaguarda" title="Retaguarda">
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeatureRetaguarda}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"Explore a integração eficaz entre o Efex PDV e o Sifat ERP. Controle o seu estoque e coletor de dados, cadastre produtos, promoções e até clientes utilizando o Sifat ERP. Sua administração financeira vai ser simplificada com acesso a dados esssenciais, permitindo que você tome decisões de negócios mais acertivas com relatórios detalhados."
							}
							tituloTipoA={true}
							titulo1={
								"Facilite sua gestão com a intregação nativa entre o PDV e o ERP"
							}
						/>
					</BlocoWrapper>
				</Feature>
				<Feature eventKey="Ponto-de-Venda" title="Ponto de Venda">
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeaturePontoDeVenda}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"Tudo o que seu cliente precisa está disponível no Efex PDV. Emita SAT CF-e e conecte vários PDVs ao mesmo SAT usando o Concentrador Fiscal. Crie arquivos SPED ICMS/PIS/COFINS integrados ao Contimatic, Prosis e Asplan. Controle seus vasilhames utilizando o ticket impresso para validação e controle dos produtos!"
							}
							tituloTipoA={true}
							titulo1={"Tenha um ponto de venda completo e otimizado"}
						/>
					</BlocoWrapper>
				</Feature>
				<Feature eventKey="Integracoes" title="Integrações">
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeatureDelivery}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"Otimize sua gestão com o Efex PDV e suas integrações. Ofereça delivery e catálogo online com o Site Mercado, importe pedidos diretamente para agilizar atendimentos. Aprimore o relacionamento com o cliente com as ferramentas CRM Mercafácil e Cresce Vendas. Acompanhe promoções de líderes com a integração Scanntech."
							}
							tituloTipoA={true}
							titulo1={"Eficiência nas integrações que seu negócio precisa"}
						/>
					</BlocoWrapper>
				</Feature>
				<Feature eventKey="Autoatendimento" title="Autoatendimento">
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeatureFiscalFinanceiro}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"Descubra o potencial do terminal de autoatendimento com o Efex PDV. Ofereça uma experiência moderna e conveniente aos seus clientes, agilizando o processo de compra. Integre facilmente o terminal com seu sistema, proporcionando mais eficiência e satisfação."
							}
							tituloTipoA={true}
							titulo1={
								"Modernize seu Atendimento com Terminal de Autoatendimento"
							}
						/>
					</BlocoWrapper>
				</Feature>
				<Feature
					eventKey="Balanca-de-pesagem"
					title="Balança de pesagem"
				>
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeatureExperienciaDoCliente}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"Otimize sua operação com a integração do Efex PDV com balanças de pesagem. Tenha controle total sobre o peso dos produtos vendidos, garantindo precisão e agilidade no atendimento. Simplifique a gestão de estoque e aumente a eficiência do seu negócio com essa integração poderosa."
							}
							tituloTipoA={true}
							titulo1={"Controle Preciso com Integração de Balanças de Pesagem"}
						/>
					</BlocoWrapper>
				</Feature>
			</FeaturesContainer>
		</Container>
	);
}

export function TituloFeaturesTextoFlutt() {
	return (
		<Container>
			<TituloContainer>
				<Titulo>Todas as funções que a sua loja precisa estão no Flutt</Titulo>
				<Subtitulo>Todas as ferramentas para você fidelizar o seu cliente estão aqui.</Subtitulo>
			</TituloContainer>
			<FeaturesContainer defaultActiveKey="Retaguarda" id="navigationTabs">
				<Feature eventKey="Retaguarda" title="Retaguarda">
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeatureRetaguarda}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"Explore a integração eficaz entre o Flutt PDV e o Sifat ERP. Você terá controle total do seu estoque, acompanhará a curva ABC e gerenciará o mix de produtos em uma interface amigável e prática. A usabilidade dos programas é fácil, permitindo que você e sua equipe se adaptem rapidamente e aproveitem ao máximo todas as suas funcionalidades."
							}
							tituloTipoA={true}
							titulo1={
								"Facilite sua gestão com a intregação nativa entre o PDV e o ERP"
							}
						/>
					</BlocoWrapper>
				</Feature>
				<Feature eventKey="Ponto-de-Venda" title="Ponto de Venda">
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeaturePontoDeVenda}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"Facilite o processo de vendas, consulte produtos, preços, estoque e aproveite outras funcionalidades desenvolvidas para aprimorar a experiência do cliente. O Flutt foi construído para garantir que seu atendimento seja rápido e objetivo."
							}
							tituloTipoA={true}
							titulo1={"Otimize o atendimento ao cliente com funções únicas"}
						/>
					</BlocoWrapper>
				</Feature>
				<Feature eventKey="App-Mobile" title="App Mobile">
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeatureDelivery}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"Com o Flutt Mobile você agiliza o atendimento da sua empresa consultando estoques, abrindo pedidos de vendas e muito mais."
							}
							tituloTipoA={true}
							titulo1={"Deixe sua gestão mais versátil"}
						/>
					</BlocoWrapper>
				</Feature>
				<Feature eventKey="Trocas-e-vale-presentes" title="Trocas e vale-presentes">
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeatureFiscalFinanceiro}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"Realize o controle e acompanhamento completo de trocas e devoluções do seu negócio, além do lançamento de vale-presentes para oferecer e vender. Com o controle de condicionais, você administra os produtos que o consumidor leva para experimentar e depois efetua a compra ou devolução, mantendo a contagem de estoque em dia de forma automática."
							}
							tituloTipoA={true}
							titulo1={
								"Faça controle de trocas, condicionais e vale-presentes"
							}
						/>
					</BlocoWrapper>
				</Feature>
				<Feature
					eventKey="Conferencia-as-cegas"
					title="Conferência às cegas"
				>
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeatureExperienciaDoCliente}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"Garanta a precisão do seu controle de caixa com as conferência cegas, que automaticamente compara as informações dos produtos, evitando perdas de dados nos processos de vendas. Conte com um controle completo de caixa para uma gestão eficiente e segura."
							}
							tituloTipoA={true}
							titulo1={"Não perca mais dados e informações essenciais do processo de vendas"}
						/>
					</BlocoWrapper>
				</Feature>
			</FeaturesContainer>
		</Container>
	);
}

export function TituloFeaturesTextoLumien() {
	return (
		<Container>
			<TituloContainer>
				<Titulo>Você tem mais controle do seu negócio com o Lumien</Titulo>
				<Subtitulo>Descubra as soluções completas que o Lumien oferece para simplificar e otimizar suas operações diárias.</Subtitulo>
			</TituloContainer>
			<FeaturesContainer defaultActiveKey="Ponto-de-Venda" id="navigationTabs">
				<Feature eventKey="Ponto-de-Venda" title="Ponto de Venda">
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeatureRetaguarda}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"Lumien é um sistema de ponto de venda integrado para postos de combustíveis, proporcionando uma gestão eficiente e automatizada. Com funcionalidades como controle de frentistas, movimentação de combustível por turno, e operação offline, o Lumien aumenta as vendas e melhora a eficiência operacional, garantindo um atendimento rápido e preciso."
							}
							tituloTipoA={true}
							titulo1={
								"O PDV verdadeiramente especializado no seu posto"
							}
						/>
					</BlocoWrapper>
				</Feature>
				<Feature eventKey="Lumien-Pay" title="Lumien Pay">
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeaturePontoDeVenda}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"Essa solução de recebimento através dos modernos SmartPOS está disponível para postos de combustíveel com sistema de automação de bombas. O Lumien Pay permite que os seus frentistas façam o recebimento sem que o seu cliente precise sair do carro. Você também pode melhorar a gestão da sua equipe podendo acompanhar as movimentações de combustíveis por turno e por frentista!"
							}
							tituloTipoA={true}
							titulo1={"Aplicativo PDV para maquinas de cartão SmartPOS"}
						/>
					</BlocoWrapper>
				</Feature>
				<Feature eventKey="Gerenciamento-Financeiro" title="Gerenciamento Financeiro">
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeatureDelivery}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"Simplifique a gestão financeira do seu posto com o Lumien. Controle o fluxo de caixa, contas a pagar e receber, operações bancárias, e faça o fechamento de caixa com facilidade, tudo integrado ao seu sistema de gestão Sifat ERP."
							}
							tituloTipoA={true}
							titulo1={"Controle total do fluxo de caixa"}
						/>
					</BlocoWrapper>
				</Feature>
				<Feature eventKey="Automacao-de-Bombas" title="Automação de Bombas">
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeatureFiscalFinanceiro}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"O Lumien se integra perfeitamente com as principais empresas de automação de bombas, como Companytec e Fusion (Wayne), proporcionando um controle preciso e automatizado do abastecimento de combustível, reduzindo erros e aumentando a eficiência operacional."
							}
							tituloTipoA={true}
							titulo1={
								"Integração avançada para um serviço eficiente"
							}
						/>
					</BlocoWrapper>
				</Feature>
				<Feature
					eventKey="Emissao-Fiscal-e-SPED"
					title="Emissão Fiscal e SPED"
				>
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeatureExperienciaDoCliente}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"Com o Lumien, a emissão de notas fiscais e a geração de SPED ICMS/IPI, SPED Contribuições, e SPED EFD se tornam processos simplificados e integrados, garantindo conformidade com todas as exigências legais."
							}
							tituloTipoA={true}
							titulo1={"Cumprimento fácil das obrigações fiscais"}
						/>
					</BlocoWrapper>
				</Feature>
			</FeaturesContainer>
		</Container>
	);
}

export function TituloFeaturesTextoWaymenu() {
	return (
		<Container>
			<TituloContainer>
				<Titulo>Aumente suas vendas com os módulos do Waymenu</Titulo>
				<Subtitulo>Conheça todas as ferramentas para o seu Food Service vender mais.</Subtitulo>
			</TituloContainer>
			<FeaturesContainer defaultActiveKey="Cardapio-Digital" id="navigationTabs">
				<Feature eventKey="Cardapio-Digital" title="Cardápio Digital">
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeatureRetaguarda}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"Crie seu cardápio digital exclusivo e conquiste clientes sem a concorrência de outros estabelecimentos. Automatize seus pedidos, imprima as comandas e fidelize seus clientes diretamente pelo WhatsApp. Modernize seu atendimento com uma plataforma que destaca seu restaurante."
							}
							tituloTipoA={true}
							titulo1={
								"Seu restaurante merece um espaço exclusivo"
							}
						/>
					</BlocoWrapper>
				</Feature>
				<Feature eventKey="Delivery" title="Delivery">
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeaturePontoDeVenda}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"No Waymenu, você pode salvar o endereço dos seus clientes na plataforma gerencial, o que agiliza o processo de pedidos. Também é possível definir se a taxa de entrega será calculada pelo raio da localização ou por bairro através da função Configuração e Cadastro de Taxas."
							}
							tituloTipoA={true}
							titulo1={"Transforme seu atendimento com o SEU site delivery"}
						/>
					</BlocoWrapper>
				</Feature>
				<Feature eventKey="Integracao-Waychef" title="Integração Waychef">
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeatureDelivery}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"Com o Waychef, sua gestão de pedidos fica mais simples e eficiente. A integração Waychef permite acompanhar cada etapa do pedido, desde o recebimento até a entrega, garantindo um processo organizado e sem complicações. Simplifique a administração do seu delivery e foque no crescimento do seu negócio."
							}
							tituloTipoA={true}
							titulo1={"Gestão simplificada para o sucesso do seu restaurante"}
						/>
					</BlocoWrapper>
				</Feature>
				<Feature eventKey="Melhores-Taxas" title="Melhores Taxas">
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeatureFiscalFinanceiro}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"Com o Waymenu, você se beneficia das melhores taxas do mercado para delivery. Economize com tarifas competitivas e aproveite a transparência nos custos operacionais. Garanta mais lucro para o seu restaurante, oferecendo um serviço de qualidade e acessível aos seus clientes."
							}
							tituloTipoA={true}
							titulo1={
								"Sem taxas abusivas, como você sempre quis"
							}
						/>
					</BlocoWrapper>
				</Feature>
				<Feature
					eventKey="Gestor-de-pedidos"
					title="Gestor de pedidos"
				>
					<BlocoWrapper>
						<BlocoTituloTextoBotaoImagem
							imagem={FeatureExperienciaDoCliente}
							possuiLogosClientes={false}
							botao1={<BotaoPlanosPrecos />}
							$corParagrafo={"black"}
							paragrafo1={
								"O gestor de pedidos do Waymenu proporciona controle total sobre o fluxo de pedidos do seu restaurante. Organize, gerencie e acompanhe todos os pedidos em um único lugar, reduzindo erros e aumentando a eficiência do seu delivery. Ofereça um atendimento impecável e conquiste a satisfação dos seus clientes com uma gestão simplificada e eficaz."
							}
							tituloTipoA={true}
							titulo1={"Controle total para um atendimento eficiente"}
						/>
					</BlocoWrapper>
				</Feature>
			</FeaturesContainer>
		</Container>
	);
}

