import BotaoModelo from "../BotaoModelo";

export function BotaoFaleConosco({ $grow }) {
	return (
		<BotaoModelo
			$backgroundcolor={"#88bd28"}
			texto={"Fale Conosco"}
			temIcone={"true"}
			$grow={$grow}
		/>
	);
}

export function BotaoEnviar() {
	return (
		<BotaoModelo
			texto={"Enviar"}
			$backgroundcolor={"#88bd28"}
			$width={"fit-content"}
			$alignself={"center"}
		/>
	);
}

export function BotaoAreaCliente() {
	return <BotaoModelo $backgroundcolor={"#1B5EA4"} texto={"Área do cliente"} />;
}

export function BotaoSejaParceiro() {
	return (
		<BotaoModelo
			$backgroundcolor={"#1B5EA4"}
			texto={"Seja um parceiro"}
			hrefUrl={null}
			customOnClick={() => {
				const SecaoPagina = document.getElementById("formParceiro");
				console.log(SecaoPagina);
				if (SecaoPagina) {
					SecaoPagina.scrollIntoView({ behavior: "smooth" });
				}
			}}
		/>
	);
}

export function BotaoEnviarMensagem() {
	return (
		<BotaoModelo
			$backgroundcolor={"#1B5EA4"}
			texto={"Enviar mensagem"}
			hrefUrl={null}
			customOnClick={() => {
				const SecaoPagina = document.getElementById("formFale");
				console.log(SecaoPagina);
				if (SecaoPagina) {
					SecaoPagina.scrollIntoView({ behavior: "smooth" });
				}
			}}
		/>
	);
}

export function BotaoQueroSerParceiro() {
	return (
		<BotaoModelo
			$backgroundcolor={"white"}
			color={"#1B355E"}
			texto={"Quero ser um parceiro Sifat"}
			$width={"fit-content"}
			$alignself={"center"}
			$padding={"2em 2em"}
			$bigradius={"var(--border-radius-l)"}
		/>
	);
}

export function BotaoReceberLigacao() {
	return (
		<BotaoModelo
			$backgroundcolor={"#1B5EA4"}
			texto={"Receber ligação"}
			customOnClick={() => {
				const SecaoPagina = document.getElementById("formLigacao");
				if (SecaoPagina) {
					SecaoPagina.scrollIntoView({ behavior: "smooth" });
				}
			}}
			hrefUrl={null}
		/>
	);
}

export function BotaoTesteGratis() {
	return <BotaoModelo $backgroundcolor={"#1B5EA4"} texto={"Teste grátis"} />;
}

export function BotaoPlanosPrecos() {
	return <BotaoModelo $backgroundcolor={"#1B5EA4"} texto={"Planos e Preços"} />;
}

export function BotaoEnviarCurriculo() {
	return (
		<BotaoModelo
			$backgroundcolor={"#1B5EA4"}
			texto={"Enviar currículo"}
			$grow={"0"}
			$width={"fit-content"}
			$alignself={"center"}
			hrefUrl={null}
			customOnClick={() => {
				const SecaoPagina = document.getElementById("formTrabalhe");
				console.log(SecaoPagina);
				if (SecaoPagina) {
					SecaoPagina.scrollIntoView({ behavior: "smooth" });
				}
			}}
		/>
	);
}
