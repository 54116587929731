import styled from "styled-components";
import BalaoTexto from "../../components/BalaoTexto";
// Imagens
import MonitorCelularERPeLumien from "../../assets/imagens/MonitorCelularERPeLumien.webp";
import Telefone from "../../assets/imagens/Telefone.webp";
import MaquininhaCelular from "../../assets/imagens/MaquininhaCelular.webp";

const Container = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1em;
	width: 100%;
`;

const ContainerImagem = styled.div`
	display: none;

	@media screen and (min-width: 993px) {
		display: flex;
		width: 50%;
	}
`;

const ContainerBaloes = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2em;

	@media screen and (min-width: 993px) {
		width: 50%;
		padding-bottom: ${(props) => props.$padding || ""};
	}
`;

const Imagem = styled.img`
	display: none;

	@media screen and (min-width: 993px) {
		display: flex;
		width: 100%;
		object-fit: contain;
		aspect-ratio: 1;
	}
`;

export function BalaoSobreSifat() {
	return (
		<Container>
			<ContainerImagem>
				<Imagem src={MonitorCelularERPeLumien} />
			</ContainerImagem>
			<ContainerBaloes>
				<BalaoTexto
					titulo={"Nossa história"}
					paragrafo1={
						"Em 1995, movida por uma visão pioneira, a Sifat iniciou suas operações com um propósito claro: simplificar a vida dos empreendedores oferecendo soluções de gestão de excelência."
					}
					paragrafo2={
						"Nossa jornada começou atendendo pequenos negócios no interior do Estado de São Paulo e, ao longo dos anos, expandimos nossa atuação para todo o Território Nacional."
					}
					$cortitulo={"#1B355E"}
					$corparagrafo={null}
				/>

				<BalaoTexto
					titulo={"Quem somos"}
					paragrafo1={
						"Somos uma empresa de tecnologia especializada em softwares de gestão empresarial, com foco em facilitar o dia a dia de uma variedade de empreendimentos, incluindo Food Services, Comércios Varejistas, Supermercados e Postos de Gasolina."
					}
					paragrafo2={
						"Com mais de 25 anos de história, mantemos nosso objetivo com a inovação, buscando sempre oferecer as melhores soluções para elevar os resultados dos nossos clientes."
					}
					$cortitulo={"#1B355E"}
					$corparagrafo={null}
				/>

				<BalaoTexto
					titulo={"Buscamos sempre mais"}
					paragrafo1={
						"Localizada em São José do Rio Preto - SP, a Sifat está empenhada em impulsionar o desenvolvimento dos negócios, trazendo modernidade e praticidade para centenas de empreendimentos, desde pequenas empresas até redes de franquias. "
					}
					paragrafo2={
						"Contamos com uma equipe de desenvolvimento altamente qualificada, composta por profissionais experientes e dedicados, que trabalham incansavelmente para oferecer o melhor em um mercado competitivo."
					}
					$cortitulo={"#1B355E"}
					$corparagrafo={null}
				/>
			</ContainerBaloes>
		</Container>
	);
}

export function BalaoSobreProdati() {
	return (
		<Container>
			<ContainerBaloes $padding={"4em"}>
				<BalaoTexto
					titulo={"Quem somos"}
					paragrafo1={
						"Empresa do Grupo Sifat, especializada no Atendimento ao Cliente, desde saudações, abordagem, apresentação do produto/serviço e a conclusão da demanda."
					}
					paragrafo2={
						"Contamos com atendentes selecionados, cujas competências e características são alinhadas com o propósito da empresa, visando a melhor solução para nossos clientes."
					}
					$cortitulo={"#4E4D4D"}
					$corparagrafo={null}
				/>

				<BalaoTexto
					titulo={"Preparo é tudo"}
					paragrafo1={"No mundo tecnológico são infindáveis possibilidades."}
					paragrafo2={
						"Assim, a Prodati disponibiliza treinamentos periódicos aos nossos colaboradores, principalmente sobre padrões de atendimento ao cliente, com monitoramento constante do nível de satisfação dos nossos clientes e atendentes."
					}
					$cortitulo={"#4E4D4D"}
					$corparagrafo={null}
				/>
			</ContainerBaloes>
			<ContainerImagem>
				<Imagem src={Telefone} />
			</ContainerImagem>
		</Container>
	);
}

export function BalaoSobreWaybe() {
	return (
		<Container>
			<ContainerBaloes $padding={"4em"}>
				<BalaoTexto
					titulo={"Quem somos"}
					paragrafo1={
						"Somos especializados no desenvolvimento de soluções em nuvem e aplicativos, com foco na gestão empresarial, especialmente em empreendimentos do ramo de Food Service, abrangendo desde redes de franquias até pequenos e médios negócios."
					}
					paragrafo2={
						"Nosso objetivo é impulsionar os resultados dos nossos clientes, contando com a experiência de anos dos nossos gestores e com uma equipe de profissionais altamente qualificados."
					}
					$cortitulo={"#1377B8"}
					$corparagrafo={null}
				/>

				<BalaoTexto
					titulo={"Preparo é tudo"}
					paragrafo1={"No mundo tecnológico são infindáveis possibilidades."}
					paragrafo2={
						"Assim, a Prodati disponibiliza treinamentos periódicos aos nossos colaboradores, principalmente sobre padrões de atendimento ao cliente, com monitoramento constante do nível de satisfação dos nossos clientes e atendentes."
					}
					$cortitulo={"#1377B8"}
					$corparagrafo={null}
				/>

				<BalaoTexto
					titulo={"Você é nossa prioridade"}
					paragrafo1={
						"Nossos colaboradores são altamente competentes e qualificados, garantindo a melhor experiência e relacionamento com você cliente, desde a contratação do produto/serviço, pós-venda e no uso contínuo."
					}
					paragrafo2={null}
					$cortitulo={"#1377B8"}
					$corparagrafo={null}
				/>
			</ContainerBaloes>
			<ContainerImagem>
				<Imagem src={MaquininhaCelular} />
			</ContainerImagem>
		</Container>
	);
}
