import styled from "styled-components";
import {
	BotaoFaleConosco,
	BotaoReceberLigacao,
	BotaoTesteGratis,
} from "../Botoes";
import { useEffect } from "react";

// Icones das funcoes
import Cadeiras from "../../assets/imagens/waychef/Cadeiras.svg";
import Caixas from "../../assets/imagens/waychef/Caixas.svg";
import Calendario from "../../assets/imagens/waychef/Calendario.svg";
import Carrinho from "../../assets/imagens/waychef/Carrinho.svg";
import Cartoes from "../../assets/imagens/waychef/Cartoes.svg";
import Conta from "../../assets/imagens/waychef/Conta.svg";
import Grafico from "../../assets/imagens/waychef/Grafico.svg";
import Graficos from "../../assets/imagens/waychef/Graficos.svg";
import Homem from "../../assets/imagens/waychef/Homem.svg";
import Impressao from "../../assets/imagens/waychef/Impressao.svg";
import Mao from "../../assets/imagens/waychef/Mao.svg";
import Monitor from "../../assets/imagens/waychef/Monitor.svg";
import Nuvem from "../../assets/imagens/waychef/Nuvem.svg";
import Papel from "../../assets/imagens/waychef/Papel.svg";
import Planilha from "../../assets/imagens/waychef/Planilha.svg";
import Recibo from "../../assets/imagens/waychef/Recibo.svg";
import Registradora from "../../assets/imagens/waychef/Registradora.svg";
import Regua from "../../assets/imagens/waychef/Regua.svg";

const ConteudoFuncoes = [
	{
		Imagem: Graficos,
		Texto: "Relatórios e dashboards gerenciais",
		Alt: "Graficos",
	},
	{ Imagem: Carrinho, Texto: "Venda de produtos", Alt: "Carrinho" },
	{ Imagem: Mao, Texto: "Cadastro de Produtos", Alt: "Mao" },
	{ Imagem: Recibo, Texto: "Contas a pagar e a receber", Alt: "Recibo" },
	{ Imagem: Papel, Texto: "Emissão de NFe", Alt: "Papel" },
	{
		Imagem: Registradora,
		Texto: "Controle e conferência de caixa",
		Alt: "Registradora",
	},
	{ Imagem: Nuvem, Texto: "Cadastro de clientes e fornecedores", Alt: "Nuvem" },
	{ Imagem: Cartoes, Texto: "Controle de cartões", Alt: "Cartoes" },
	{
		Imagem: Monitor,
		Texto: "Cadastro de clientes e fornecedores",
		Alt: "Monitor",
	},
	{
		Imagem: Impressao,
		Texto: "Múltiplos locais de produção",
		Alt: "Impressao",
	},
	{ Imagem: Cadeiras, Texto: "Controle de mesas e fichas", Alt: "Cadeiras" },
	{ Imagem: Grafico, Texto: "DRE anual (mês a mês)", Alt: "Grafico" },
	{ Imagem: Planilha, Texto: "Crédito na fatura", Alt: "Planilha" },
	{ Imagem: Regua, Texto: "Variação de produtos", Alt: "Regua" },
	{
		Imagem: Homem,
		Texto: "Usuários e conexões simultâneas no ERP",
		Alt: "Homem",
	},
	{
		Imagem: Calendario,
		Texto: "Controle de vendas a prazo",
		Alt: "Calendario",
	},
	{ Imagem: Caixas, Texto: "Múltiplos locais de estocagem", Alt: "Caixas" },
	{ Imagem: Conta, Texto: "Múltiplas tabelas de preços", Alt: "Conta" },
];

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1.5em;
	width: 100%;
`;

const Titulo = styled.h1`
	color: white;
	text-align: left;
	font-weight: var(--strong-weight);
	font-size: var(--bigger-font);
`;

const FuncoesContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	gap: 0.5em;
	width: 100%;

	@media screen and (max-width: 650px) {
		overflow-x: scroll;
	}
`;

const BotoesContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 1.2em;
	width: 100%;
	justify-content: center;
`;

const FuncaoContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	padding: 0.7em 0.9em;
	background-color: white;
	border-radius: var(--border-radius-s);
	align-items: center;
	height: 6em;
	filter: drop-shadow(1px 1px 3px black);

	@media screen and (max-width: 650px) {
		width: 50vw;
	}

	@media screen and (min-width: 651px) {
		height: 4em;
	}
`;

const PaginacaoFuncoes = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5em;
	opacity: 0.9;
	flex: 1;
`;

const ImagemFuncao = styled.img`
	width: 2.5em;
	height: auto;
`;

const TextoFuncao = styled.span`
	font-weight: var(--weak-weight);
	font-size: var(--small-font);
	color: #4e4d4d;
	text-align: center;
	width: 100%;
`;

function GerarFuncao(indexInicial) {
	let funcoes = [];

	for (let i = indexInicial; i < indexInicial + 6; i++) {
		funcoes.push(
			<FuncaoContainer key={i}>
				<ImagemFuncao
					src={ConteudoFuncoes[i].Imagem}
					alt={"Imagem de " + ConteudoFuncoes[i].Alt}
				/>
				<TextoFuncao>{ConteudoFuncoes[i].Texto}</TextoFuncao>
			</FuncaoContainer>
		);
	}

	return funcoes;
}

export default function FuncoesWaychef() {
	useEffect(() => {
		const funcoesContainerDom = document.getElementById("funcoesContainer");
		const paginacaoFuncoes1 = document.getElementById("paginacaoFuncoes1");
		const paginacaoFuncoes2 = document.getElementById("paginacaoFuncoes2");
		const paginacaoFuncoes3 = document.getElementById("paginacaoFuncoes3");
		const larguraTotal = window.innerWidth;
		const larguraContainer = funcoesContainerDom.clientWidth;
		const offsetLaterais = (larguraTotal - larguraContainer) / 2;
		const offsetEsquerda = offsetLaterais;
		const metadeContainer = larguraContainer / 2 + offsetEsquerda;
		let tamanhoTela = window.screen.width;

		if (tamanhoTela < 1000) {
			paginacaoFuncoes2.style.opacity = 0.2;
			paginacaoFuncoes3.style.opacity = 0.2;
		}

		funcoesContainerDom.addEventListener("scroll", function () {
			tamanhoTela = window.screen.width;

			const posicaoXpaginacao1 = paginacaoFuncoes1.getBoundingClientRect().x;
			const posicaoXpaginacao2 = paginacaoFuncoes2.getBoundingClientRect().x;
			const posicaoXpaginacao3 = paginacaoFuncoes3.getBoundingClientRect().x;

			if (tamanhoTela < 1000) {
				if (
					posicaoXpaginacao1 > offsetEsquerda - 10 &&
					posicaoXpaginacao1 < metadeContainer
				) {
					paginacaoFuncoes1.style.opacity = 0.9;
				} else {
					paginacaoFuncoes1.style.opacity = 0.2;
				}

				if (
					posicaoXpaginacao2 > offsetEsquerda &&
					posicaoXpaginacao2 < metadeContainer
				) {
					paginacaoFuncoes2.style.opacity = 0.9;
				} else {
					paginacaoFuncoes2.style.opacity = 0.2;
				}

				if (
					posicaoXpaginacao3 > offsetEsquerda &&
					posicaoXpaginacao3 < metadeContainer
				) {
					paginacaoFuncoes3.style.opacity = 0.9;
				} else {
					paginacaoFuncoes3.style.opacity = 0.2;
				}
			}
		});
	}, []);

	return (
		<Container>
			<Titulo>
				No Waychef você encontra todas as funções que o seu negócio precisa
			</Titulo>
			<FuncoesContainer id={"funcoesContainer"}>
				<PaginacaoFuncoes id={"paginacaoFuncoes1"}>
					{GerarFuncao(0)}
				</PaginacaoFuncoes>

				<PaginacaoFuncoes id={"paginacaoFuncoes2"}>
					{GerarFuncao(6)}
				</PaginacaoFuncoes>

				<PaginacaoFuncoes id={"paginacaoFuncoes3"}>
					{GerarFuncao(12)}
				</PaginacaoFuncoes>
			</FuncoesContainer>
			<BotoesContainer>
				<BotaoFaleConosco />
				<BotaoReceberLigacao />
				<BotaoTesteGratis />
			</BotoesContainer>
		</Container>
	);
}
