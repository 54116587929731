import styled from "styled-components";
import Logo from "../../assets/imagens/LogoSifatAzul.svg";

const Container = styled.div`
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.2em;
	border-radius: var(--border-radius-m);
	padding: 1em;

	@media screen and (min-width: 550px) {
		padding: 3em;
	}

	@media screen and (min-width: 993px) {
		flex-direction: row;
		align-items: unset;
		gap: 2rem;
	}
`;

const ContainerLogo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: ${(props) => props.$gap || "1.2em"};

	@media screen and (min-width: 993px) {
		width: 30%;
	}
`;

const ContainerNumeros = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: ${(props) => props.$gap || "1.2em"};

	@media screen and (min-width: 993px) {
		width: 70%;
		align-items: unset;
		justify-content: space-between;
		gap: 0;
	}
`;

const NumeroGrandeContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (min-width: 993px) {
		flex-direction: row;
		gap: 1rem;
	}
`;

const NumeroGrande = styled.span`
	color: #1b5ea4;
	font-weight: var(--strong-weight);
	font-size: 4em;
`;

const NumeroGrandeWrapper = styled.div`
	display: flex;
	flex: 1;
	justify-content: flex-end;
`;

const Titulo = styled.h1`
	font-weight: var(--strong-weight);
	font-size: var(--bigger-font);
	text-align: center;

	@media screen and (min-width: 993px) {
		text-align: left;
	}
`;

const LogoSifat = styled.img`
	width: 7em;
	height: auto;

	@media screen and (min-width: 993px) {
		width: auto;
	}
`;

const Paragrafo = styled.p`
	text-align: center;
	margin: 0;
	font-weight: var(--regular-weight);
	font-size: var(--medium-font);

	@media screen and (min-width: 993px) {
		text-align: left;
	}
`;

const ParagrafoWrapper = styled.div`
	display: flex;
	flex: 1;

	@media screen and (max-width: 993px) {
		margin-top: -0.8em;
	}
`;

export default function NumerosDaSifatTop() {
	return (
		<Container>
			<ContainerLogo>
				<Titulo>
					Excelência em{" "}
					<span style={{ color: "#1B5EA4" }}>sistemas de gestão.</span>
				</Titulo>
				<LogoSifat src={Logo} alt={"Logo Sifat"} />
			</ContainerLogo>
			<ContainerNumeros $gap={"2em"}>
				<Paragrafo>
					Encontre a solução certa para a gestão do seu negócio. Invista no seu
					sistema ERP com quem tem anos de experiência e estabilidade no
					mercado!
				</Paragrafo>
				<NumeroGrandeContainer>
					<NumeroGrandeWrapper>
						<NumeroGrande>28</NumeroGrande>
					</NumeroGrandeWrapper>
					<ParagrafoWrapper>
						<Paragrafo>ANOS DE EXPERIÊNCIA COM SISTEMA DE GESTÃO</Paragrafo>
					</ParagrafoWrapper>
				</NumeroGrandeContainer>
				<NumeroGrandeContainer>
					<NumeroGrandeWrapper>
						<NumeroGrande>23.000</NumeroGrande>
					</NumeroGrandeWrapper>
					<ParagrafoWrapper>
						<Paragrafo>
							ESTAÇÕES DE TRABALHO FUNCIONANDO A TODO O VAPOR
						</Paragrafo>
					</ParagrafoWrapper>
				</NumeroGrandeContainer>
				<NumeroGrandeContainer>
					<NumeroGrandeWrapper>
						<NumeroGrande>6.000</NumeroGrande>
					</NumeroGrandeWrapper>
					<ParagrafoWrapper>
						<Paragrafo>CLIENTES SATISFEITOS</Paragrafo>
					</ParagrafoWrapper>
				</NumeroGrandeContainer>
				<NumeroGrandeContainer>
					<NumeroGrandeWrapper>
						<NumeroGrande>27</NumeroGrande>
					</NumeroGrandeWrapper>
					<ParagrafoWrapper>
						<Paragrafo>ESTADOS ATENDIDOS</Paragrafo>
					</ParagrafoWrapper>
				</NumeroGrandeContainer>
			</ContainerNumeros>
		</Container>
	);
}
