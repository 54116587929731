// Componentes personalizados
import BannerNavegacao from "../../components/BannerNavegacao";
import { BalaoSobreSifat } from "../../components/BaloesTexto";
import { QualidadesSifat } from "../../components/Qualidades";
import SecaoPagina from "../../components/SecaoPagina";
import { LideresSifat, ProdutosSifat } from "../../components/GrupoCirculos";
import PageLayout from "../../layouts/PageLayout";
// Imagens
import BannerAzulMonitorCelular from "../../assets/imagens/BannerAzulMonitorCelular.png";
import BannerBrancoOnda from "../../assets/imagens/BannerBrancoOnda.png";
import FundoAzulOnda from "../../assets/imagens/FundoAzulOnda.webp";

export default function PaginaSobreSifat() {
	return (
		<PageLayout>
			<BannerNavegacao pagina={"Sifat"} />

			<SecaoPagina
				$gap={"2em"}
				$bgimagem={BannerAzulMonitorCelular}
				$paddingmin={"2em 1em"}
				$position={"right"}
			>
				<BalaoSobreSifat />
			</SecaoPagina>

			<SecaoPagina
				$bgimagem={BannerBrancoOnda}
				$position={"left"}
				$paddingmax={"4em 4em 0"}
				$isdesktop={true}
			>
				<QualidadesSifat />
			</SecaoPagina>

			<SecaoPagina
				$bgimagem={FundoAzulOnda}
				$paddingmin={"2em 2em 9em"}
				$paddingmid={"2em 2em 9em"}
				$paddingmax={"4em 4em 8em"}
				$position={"center"}
				$gap={"3em"}
				$isdesktop={true}
			>
				<ProdutosSifat />
				<LideresSifat />
			</SecaoPagina>
		</PageLayout>
	);
}
