import styled from "styled-components";

const Container = styled.div`
	background-color: #fea106;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2em;
	border-radius: var(--border-radius-s);
	gap: 1em;
	justify-content: flex-end;

	@media screen and (min-width: 650px) {
		width: 45%;
	}
`;

const Titulo = styled.h1`
	color: white;
	font-weight: var(--strong-weight);
	font-size: var(--big-font);
	text-align: center;
`;

const Valor = styled.span`
	color: #f05b51;
	background-color: white;
	padding: 0.5em 0;
	width: 100%;
	text-align: center;
	font-weight: var(--strong-weight);
	font-size: var(--bigger-font);
	border-radius: var(--border-radius-s);
`;
const Subtitulo = styled.h2`
	color: black;
	font-weight: var(--regular-weight);
	font-size: var(--smallest-font);
	text-align: center;
`;

export default function CaixaPreco({ titulo, valor }) {
	return (
		<Container>
			<Titulo>{titulo}</Titulo>
			<Valor>{valor}</Valor>
			<Subtitulo>
				Acrescenta na mensalidade a partir do plano Plano Bronze.
			</Subtitulo>
		</Container>
	);
}
