import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: left;
	padding: 1em;
	border: 2px solid #1b5ea4;
	border-radius: var(--border-radius-s);
	background-color: #1b5ea429;

	@media screen and (min-width: 650px) {
		width: 45%;
	}

	@media screen and (min-width: 993px) {
		backdrop-filter: blur(10px);
		width: ${(props) => props.$width || "45%"};
	}
`;

const Imagem = styled.img`
	width: 5em;
	height: auto;
	margin-bottom: 1em;
`;

const Titulo = styled.h1`
	color: #1b5ea4;
	font-weight: var(--strong-weight);
	font-size: var(--smaller-font);
`;

const Paragrafo = styled.p`
	margin: 0;
	font-weight: var(--regular-weight);
	font-size: var(--smaller-font);
`;

export default function CardQualidade({ imagem, titulo, paragrafo, $width }) {
	// Extrair nome da imagem
	const arrayUrlImagem = imagem.split("/");
	const tamanho = arrayUrlImagem.length;
	const arrayNomeImagem = arrayUrlImagem[tamanho - 1];
	const nomeImagem = arrayNomeImagem.split(".")[0];

	return (
		<Container $width={$width}>
			<Imagem src={imagem} alt={`Imagem de ${nomeImagem}`} />
			<Titulo>{titulo}</Titulo>
			<Paragrafo>{paragrafo}</Paragrafo>
		</Container>
	);
}
