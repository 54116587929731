import styled from "styled-components";

const ContatoContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1em;
`;

const Titulo = styled.h1`
	font-weight: var(--strong-weight);
	font-size: var(--bigger-font);
	color: #4e4d4d;
	text-align: center;
`;

const TituloHorario = styled.h2`
	font-weight: var(--strong-weight);
	font-size: var(--medium-font);
	color: #4e4d4d;
	margin: 0;
`;

const Horario = styled.h3`
	font-weight: var(--weak-weight);
	font-size: var(--small-font);
	color: #4e4d4d;
`;

const HorariosContainer = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 1.5em;
	margin-top: 1em;
`;

const Telefone = styled.button`
	border: none;
	border-radius: var(--border-radius-m);
	font-size: var(--small-font);
	height: 2em;
	padding: 1.2em 2em;
	font-weight: var(--strong-weight);
	background-color: #88bd28;
	color: white;
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const NomeTelefone = styled.h3`
	font-weight: var(--weak-weight);
	font-size: var(--medium-font);
	color: #4e4d4d;
`;

const NomeETelefoneContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (min-width: 650px) {
		width: 45%;
	}
`;

const TelefonesContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1em;

	@media screen and (min-width: 650px) {
		flex-direction: row;
		gap: 1em;
		flex-wrap: wrap;
		justify-content: space-evenly;
	}
`;

function RedirecionarAoTelefone(evt) {
	let number = evt.target.innerText;
	number = "0" + number;
	number = number.replaceAll("(", "");
	number = number.replaceAll(")", "");
	number = number.replaceAll("-", "");
	number = number.replaceAll(" ", "");

	window.location.href = "tel:" + number;
}

export default function HorariosAtendimento() {
	return (
		<ContatoContainer>
			<Titulo>CENTRAL DE ATENDIMENTO</Titulo>
			<Telefone
				onClick={(evt) => {
					RedirecionarAoTelefone(evt);
				}}
			>
				(17) 3122-8600
			</Telefone>
			<TelefonesContainer>
				<NomeETelefoneContainer>
					<NomeTelefone>WhatsApp</NomeTelefone>
					<Telefone>(17) 99634-9340</Telefone>
				</NomeETelefoneContainer>
				<NomeETelefoneContainer>
					<NomeTelefone>Ribeirão Preto</NomeTelefone>
					<Telefone
						onClick={(evt) => {
							RedirecionarAoTelefone(evt);
						}}
					>
						(16) 3456-8686
					</Telefone>
				</NomeETelefoneContainer>
				<NomeETelefoneContainer>
					<NomeTelefone>Unidade São Paulo</NomeTelefone>
					<Telefone
						onClick={(evt) => {
							RedirecionarAoTelefone(evt);
						}}
					>
						(11) 2832-8600
					</Telefone>
				</NomeETelefoneContainer>
				<NomeETelefoneContainer>
					<NomeTelefone>Unidade Barretos</NomeTelefone>
					<Telefone
						onClick={(evt) => {
							RedirecionarAoTelefone(evt);
						}}
					>
						(17) 3122-8600
					</Telefone>
				</NomeETelefoneContainer>
			</TelefonesContainer>
			<HorariosContainer>
				<div>
					<TituloHorario>Atendimento Horário Comercial</TituloHorario>
					<Horario>
						De segunda à sexta-feira das 07h às 19h | Sábado das 07h às 13h.
					</Horario>
				</div>
				<div>
					<TituloHorario>Atendimento Plantão:</TituloHorario>
					<Horario>
						De segunda a sexta-feira das 19h às 00h | Sábado das 13h às 00h |
						Domingo 07h às 18h.
					</Horario>
				</div>
				<div>
					<TituloHorario>Atendimento WhatsApp:</TituloHorario>
					<Horario>De segunda a sexta-feira das 08h às 17h30.</Horario>
				</div>
			</HorariosContainer>
		</ContatoContainer>
	);
}
